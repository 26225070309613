<!--
Simple Confirm prompt using EditDialog
Offers only a slot and @accept event
-->
<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    >
      <v-btn
        :disabled="disabled"
        small
        :color="color"
        @click="dialog = !dialog"
      >
        {{ text }}
      </v-btn>
    </slot>
    <confirm-dialog
      v-if="dialog"
      v-model="dialog"
      @accept="$emit('accept')"
    >
      <slot />
    </confirm-dialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/base/components/ConfirmDialog'
export default {
  name: 'ConfirmButton',
  components: { ConfirmDialog },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String,
      default: 'confirm'
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
