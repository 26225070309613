<template>
  <v-dialog
    v-model="filterDialog"
    max-width="800"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        fab
        right
        bottom
        fixed
        color="primary"
      >
        <v-badge
          :value="filterCount"
          color="error"
          :content="filterCount"
        >
          <v-icon>
            mdi-filter
          </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card>
      <v-form
        @submit.prevent="applyFilter"
      >
        <v-card-title>
          Filters
        </v-card-title>
        <v-card-text>
          <strong>Lots</strong> and <strong>Hedges</strong> can be filtered by Smelter and Lot Type.
          <br>
          <strong>Available Yards</strong> allows filtering of Hedges.  Populated by yards attached to Hedges that are currently available.
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <lot-name-field
                v-model="lotName"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <hedge-id-field
                v-model="hedgeId"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <smelter-hedge-id-field
                v-model="smelterHedgeId"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                label="Smelter"
                v-model="smelter"
                item-text="name"
                item-value="id"
                return-object
                :items="smelters"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                label="Lot Type"
                v-model="lotType"
                item-text="text"
                item-value="value"
                return-object
                :items="lotTypeList"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <search-autocomplete
                v-model="manager"
                label="Manager Search (hedge filter)"
                hint="filters hedges for manager"
                api-path="/api/users/"
                item-value="username"
                :params="{ is_active: true, fields: 'username' }"
                :format-entry="x => ({ friendlyName: x.username, username: x.username })"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                label="Available Yards"
                v-model="yard"
                item-text="nameAddress"
                item-value="value"
                return-object
                :items="yards"
              />
            </v-col>
          </v-row>
          <p>
            Hint: use <strong>TAB</strong> to change boxes and <strong>ENTER</strong> to apply the filters.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="accent"
            text
            @click="filterDialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
          >
            apply
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import HedgeIdField from '@/views/Hedges/Allocations/components/Inputs/HedgeIdField.vue'
import SmelterHedgeIdField from '@/views/Hedges/Allocations/components/Inputs/SmelterHedgeIdField.vue'
import LotNameField from '@/views/Hedges/Allocations/components/Inputs/LotNameField.vue'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'

export default {
  name: 'AllocationFilterDialog',
  components: { LotNameField, SmelterHedgeIdField, HedgeIdField, SearchAutocomplete },
  data: () => ({
    filterDialog: false,
    smelter: null,
    lotType: null,
    yard: null,
    lotName: '',
    hedgeId: '',
    smelterHedgeId: '',
    manager: ''
  }),
  watch: {
    filterDialog (opened) {
      if (opened) this.setup()
    }
  },
  computed: {
    ...mapGetters('lotStore', [
      'lotTypes'
    ]),
    ...mapGetters('hedgeBank', [
      'getManager',
      'getLotName',
      'getHedgeId',
      'getSmelterHedgeId',
      'getSmelter',
      'getLotType',
      'getSmelters',
      'getFilterableYards',
      'getYardFilter'
    ]),
    lotTypeList () {
      return [{ text: 'All Types', value: null }, ...this.lotTypes]
    },
    smelters () {
      return [{ name: 'All Smelters', id: null }, ...this.getSmelters]
    },
    yards () {
      const createNameAddress = this.getFilterableYards.map(yard => {
        return Object.assign({ nameAddress: `${yard.name} | ${yard.full_address}` }, yard)
      })
      return [{ id: null, nameAddress: 'All Yards' }, ...createNameAddress]
    },
    filterCount () {
      let count = 0
      if (this.getLotType && this.getLotType.value) count += 1
      if (this.getSmelter && this.getSmelter.id) count += 1
      if (this.getYardFilter && this.getYardFilter.id) count += 1
      if (this.lotName) count += 1
      if (this.hedgeId) count += 1
      if (this.smelterHedgeId) count += 1
      return count
    }
  },
  methods: {
    ...mapMutations('hedgeBank', [
      'setManager',
      'selectLotType',
      'selectSmelter',
      'selectYardFilter',
      'setLotName',
      'setHedgeId',
      'setSmelterHedgeId'
    ]),
    applyFilter () {
      this.setManager(this.manager)
      this.selectSmelter(this.smelter)
      this.selectLotType(this.lotType)
      this.selectYardFilter(this.yard)
      this.setLotName(this.lotName)
      this.setHedgeId(this.hedgeId)
      this.setSmelterHedgeId(this.smelterHedgeId)
      this.filterDialog = false
    },
    setup () {
      this.manager = this.getManager
      this.smelter = this.getSmelter
      this.lotType = this.getLotType
      this.yard = this.getYardFilter
      this.lotName = this.getLotName
      this.hedgeId = this.getHedgeId
      this.smelterHedgeId = this.getSmelterHedgeId
    }
  },
  mounted () {
    this.setup()
  }
}
</script>
