<template>
  <unauthorized
    :authorized="$store.getters.can('edit-smelters')"
    message="Unauthorized to view this page"
  >
    <!-- Skeleton Loader if loading -->
    <v-container v-if="loading">
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="heading" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-skeleton-loader type="table-row" />
        </v-col>
      </v-row>
    </v-container>
    <!-- If not loading and loaded a smelter -->
    <v-container v-else-if="!loading && smelter">
      <url-breadcrumbs
        v-if="smelter"
        :items="[
          ['smelter list', { name: 'smelter-list' }],
          [`smelter #${smelter.id}`, { name: 'smelter', params: { id: this.$route.params.id }}],
          [`Edit`]
        ]"
      />
      <v-alert
        dark
        v-if="changedCounter > 0"
        color="primary"
      >
        You have unsaved changes
      </v-alert>
      <v-row>
        <v-col>
          <h1 class="title">
            Editing Smelter {{ `${smelter.name}` }}
            <span class="subtitle-1">
              (code {{ smelter.code }})
            </span>
          </h1>
        </v-col>
      </v-row>
      <ValidationObserver v-slot="{ invalid, errors }">
        <v-form @submit.prevent>
          <v-toolbar
            dense
            flat
          >
            <user-upload-dialog
              class="d-inline mr-3"
              :file-links="fileLinks"
            />
          </v-toolbar>
          <v-row>
            <v-col
              cols="12"
              lg="7"
            >
              <smelter-overview-edit-card
                :smelter-overview="smelterOverview"
                @overviewUpdated="updateOverview($event)"
              />
              <smelter-terms-edit-card
                :smelter-terms="smelterTerms"
                @termsUpdated="updateTerms($event)"
              />
            </v-col>
            <!-- Contacts -->
            <v-col
              class="pt-0"
              :class="{'pt-6': $vuetify.breakpoint.lgAndUp }"
              cols="12"
              lg="5"
            >
              <!-- Changes made in contacts-list-editor-card update contactsDetails -->
              <contacts-list-editor-card
                v-if="contactsDetails !== null"
                :contacts="contactsDetails"
                @contacts-updated="updateContacts($event)"
              />
            </v-col>
          </v-row>
          <!-- Disabled if there are no unsaved changes or if the form is invalid -->
          <v-btn
            :disabled="invalid || changedCounter <= 0"
            fixed
            bottom
            right
            fab
            color="primary"
            type="submit"
            @click="updateSmelter"
          >
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-form>
      </ValidationObserver>
    </v-container>
    <!-- Failed to initialize form -->
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <p>Failed to initialize form for Smelter #{{ $route.params.id }}</p>
        </v-col>
      </v-row>
    </v-container>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import fileLinks from '@/helpers/fileLinks'
import UserUploadDialog from '@/base/components/UserUploadDialog'
import ContactsListEditorCard from '@/base/components/ContactsListEditorCard'
import SmelterOverviewEditCard from '@/views/Smelter/components/SmelterOverviewEditCard'
import SmelterTermsEditCard from '@/views/Smelter/components/SmelterTermsEditCard'

export default {
  name: 'EditSmelter',
  components: {
    ContactsListEditorCard,
    UserUploadDialog,
    SmelterOverviewEditCard,
    SmelterTermsEditCard
  },
  data: () => ({
    // starts as negative because setting form data causes a change
    changedCounter: 0,
    loading: false,
    smelter: null,
    smelterOverview: null,
    smelterTerms: null,
    contactsDetails: null
  }),
  computed: {
    // Get links to this smelter's uploads
    fileLinks () {
      return fileLinks.smelterFileLinks(this.smelter)
    }
  },
  methods: {
    /**
     * Send a PATCH request to the API with user's smelter updates
     * @returns {Promise<void>}
     */
    async updateSmelter () {
      try {
        let updates = {
          ...this.smelterTerms,
          ...this.smelterOverview,
          contactsDetails: this.contactsDetails
        }
        let payload = {
          name: updates.name,
          email: updates.email,
          phone_number: updates.phoneNumber,
          address: updates.address,
          notes: updates.notes,
          is_active: updates.isActive,
          max_carbon_lbs_per_period: updates.maxCarbonLbsPerPeriod,
          pt_return_rate: updates.ptReturnRate,
          pd_return_rate: updates.pdReturnRate,
          rh_return_rate: updates.rhReturnRate,
          min_lbs_per_period: updates.minLbsPerPeriod,
          treatment_rate: updates.treatmentRate,
          analysis_fee: updates.analysisFee,
          value_days: updates.valueDays,
          time_period: updates.timePeriod,
          finance_charge: updates.financeCharge,
          carbon_limit_per_lot: updates.carbonLimitPerLot,
          avg_carbon_content: updates.avgCarbonContent,
          additional_terms: updates.additionalTerms,
          contacts_details: updates.contactsDetails,
          expected_result_return: updates.expectedResultReturn
        }
        const res = await axios.patch(`/api/smelters/${this.$route.params.id}/`, payload)
        this.initializeForm(res.data) // updates the form values when the patch is performed
        // 3 changes occur after update.  Overview, terms, contacts
        this.changedCounter = -3
        await this.$router.push({ name: 'smelter' })
      } catch {
        this.$store.commit('setSnackbarError', `Failed to update Smelter ${this.$route.params.id}`)
      }
    },
    updateOverview (overview) {
      this.smelterOverview = overview
      this.changedCounter++
    },
    updateTerms (terms) {
      this.smelterTerms = terms
      this.changedCounter++
    },
    /**
     * Update the local contactsDetails object to reflect user changes made in the contacts-list-editor-card
     * @param contacts
     */
    updateContacts (contacts) {
      if (contacts) this.contactsDetails = contacts
      this.changedCounter++
    },
    /**
     * Request a smelter object by ID from the API
     * @returns {Promise<void>}
     */
    async fetchSmelter () {
      try {
        const res = await axios.get(`/api/smelters/${this.$route.params.id}/`)
        this.smelter = res.data
      } catch {
        this.$store.commit('setSnackbarError', `Failed to fetch Smelter ${this.$route.params.id}`)
      }
    },
    /**
     * Update local smelter properties so the form input values match the retrieved smelter's properties initially
     * @param smelter {Object} The smelter being edited
     */
    initializeForm (smelter) {
      this.smelterOverview = {
        name: smelter.name,
        phoneNumber: smelter.phone_number,
        email: smelter.email,
        address: smelter.address,
        notes: smelter.notes,
        isActive: smelter.is_active
      }
      this.smelterTerms = {
        ptReturnRate: smelter.pt_return_rate,
        pdReturnRate: smelter.pd_return_rate,
        rhReturnRate: smelter.rh_return_rate,
        treatmentRate: smelter.treatment_rate,
        analysisFee: smelter.analysis_fee,
        valueDays: smelter.value_days,
        minLbsPerPeriod: smelter.min_lbs_per_period,
        maxCarbonLbsPerPeriod: smelter.max_carbon_lbs_per_period,
        timePeriod: smelter.time_period,
        financeCharge: smelter.finance_charge,
        carbonLimitPerLot: smelter.carbon_limit_per_lot,
        avgCarbonContent: smelter.avg_carbon_content,
        additionalTerms: smelter.additional_terms,
        expectedResultReturn: smelter.expected_result_return
      }
      this.contactsDetails = smelter.contacts_details
    }
  },
  // At mounted, fetch Smelter and fill form fields with its properties
  async mounted () {
    this.loading = true
    await this.fetchSmelter()
    await this.initializeForm(this.smelter)
    this.loading = false
  }
}
</script>
