<!--
Simple Confirm prompt using EditDialog
Offers only a slot and @accept event
-->
<template>
  <edit-dialog
    :value="value"
    headline-text="Confirm"
    decline-text="Cancel"
    accept-text="Confirm"
    @accept="$emit('accept')"
    @input="$emit('input', $event)"
  >
    <slot />
  </edit-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>
