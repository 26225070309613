var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-subtitle',[_vm._v("Overview")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Name","error-messages":errors},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":"Active"},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Phone Number","error-messages":errors},model:{value:(_vm.formData.phoneNumber),callback:function ($$v) {_vm.$set(_vm.formData, "phoneNumber", $$v)},expression:"formData.phoneNumber"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Email Address","error-messages":errors},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Address","error-messages":errors},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"px-4",attrs:{"label":"Notes","error-messages":errors},model:{value:(_vm.formData.notes),callback:function ($$v) {_vm.$set(_vm.formData, "notes", $$v)},expression:"formData.notes"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }