<template>
  <!-- Overview -->
  <v-row>
    <v-col>
      <v-card>
        <v-card-subtitle>Overview</v-card-subtitle>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="required|max:30"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Name"
                v-model="formData.name"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-checkbox
              label="Active"
              v-model="formData.isActive"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="max:40"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Phone Number"
                v-model="formData.phoneNumber"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="email"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Email Address"
                class="px-4"
                v-model="formData.email"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
          >
            <ValidationProvider
              rules="max:512"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Address"
                v-model="formData.address"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <ValidationProvider
              rules="max:512"
              v-slot="{ errors }"
            >
              <v-textarea
                label="Notes"
                v-model="formData.notes"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SmelterOverviewEditCard',
  props: {
    smelterOverview: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    formData: null
  }),
  watch: {
    smelterOverview: {
      handler () {
        this.formData = this.smelterOverview
      },
      immediate: true
    },
    formData: {
      handler () {
        this.$emit('overviewUpdated', this.formData)
      },
      deep: true
    }
  }
}
</script>
