<template>
  <v-alert
    v-if="$auth.user.fx_currency !== 'USD'"
    class="d-print-none"
    type="info"
    color="primary"
  >
    This page applies the <strong>USD</strong>  currency. Changing the global currency to USD may help prevent conflicts.
  </v-alert>
</template>
<script>
export default {
  name: 'UsdForcedAlert'
}
</script>
