<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <hedge-id-field v-model="hedgeIdFilter" />
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <smelter-hedge-id-field v-model="smelterHedgeIdFilter" />
    </v-col>
  </v-row>
</template>

<script>
import SmelterHedgeIdField from '@/views/Hedges/Allocations/components/Inputs/SmelterHedgeIdField.vue'
import HedgeIdField from '@/views/Hedges/Allocations/components/Inputs/HedgeIdField.vue'

export default {
  name: 'AllocationHedgeFilterFields',
  components: {
    SmelterHedgeIdField,
    HedgeIdField
  },
  computed: {
    hedgeIdFilter: {
      get () {
        return this.$store.getters['hedgeBank/getHedgeId']
      },
      set (value) {
        this.$store.commit('hedgeBank/setHedgeId', value)
      }
    },
    smelterHedgeIdFilter: {
      get () {
        return this.$store.getters['hedgeBank/getSmelterHedgeId']
      },
      set (value) {
        this.$store.commit('hedgeBank/setSmelterHedgeId', value)
      }
    }
  }
}
</script>
