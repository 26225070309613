<script>
import DataDumpReport from '@/base/filter/DataDumpReport'

export default {
  mixins: [DataDumpReport],
  name: 'HedgeDumpReport',
  data: function () {
    return {
      title: 'Hedge Data Dump Report',
      action: 'view-hedge-dump-report',

      baseUrl: '/api/hedges/',
      ready: false,
      exportData: [],
      possibleFields: [],

      fields: [
        'id',
        'smelter',
        'pd_available_toz',
        'pd_estimated_toz',
        'pd_initial_toz',
        'pd_rate_per_toz',
        'pt_available_toz',
        'pt_estimated_toz',
        'pt_initial_toz',
        'pt_rate_per_toz',
        'rh_available_toz',
        'rh_estimated_toz',
        'rh_initial_toz',
        'rh_rate_per_toz',
        'memo',
        'expires_on',
        'created_at'
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },

        { name: 'Reference', prop: 'reference', type: String, query: 'reference__icontains' },
        { name: 'PT Rate Gte', prop: 'ptRatePerTozGte', type: String, query: 'pt_rate_per_toz__gte' },
        { name: 'PT Rate Lte', prop: 'ptRatePerTozLte', type: String, query: 'pt_rate_per_toz__lte' },
        { name: 'PT Avail Gte', prop: 'ptAvailableTozGte', type: String, query: 'pt_available_toz__gte' },
        { name: 'PT Avail Lte', prop: 'ptAvailableTozLte', type: String, query: 'pt_available_toz__lte' },
        { name: 'PD Rate Gte', prop: 'pdRatePerTozGte', type: String, query: 'pd_rate_per_toz__gte' },
        { name: 'PD Rate Lte', prop: 'pdRatePerTozLte', type: String, query: 'pd_rate_per_toz__lte' },
        { name: 'PD Avail Gte', prop: 'pdAvailableTozGte', type: String, query: 'pd_available_toz__gte' },
        { name: 'PD Avail Lte', prop: 'pdAvailableTozLte', type: String, query: 'pd_available_toz__lte' },
        { name: 'RH Rate Gte', prop: 'rhRatePerTozGte', type: String, query: 'rh_rate_per_toz__gte' },
        { name: 'RH Rate Lte', prop: 'rhRatePerTozLte', type: String, query: 'rh_rate_per_toz__lte' },
        { name: 'RH Avail Gte', prop: 'rhAvailableTozGte', type: String, query: 'rh_available_toz__gte' },
        { name: 'RH Avail Lte', prop: 'rhAvailableTozLte', type: String, query: 'rh_available_toz__lte' },
        // { name: 'Has Allocation', prop: 'withAlloc', type: String, query: 'allocation_set' },

        { name: 'smelters', prop: 'smelterSet', type: Array, query: 'smelter__name__in' },

        { name: 'Exp. After', prop: 'startDate', type: String, query: 'expires_on__gte' },
        { name: 'Exp. Before', prop: 'endDate', type: String, query: 'expires_on__lte' },
        { name: 'Created After', prop: 'createdAfter', type: String, query: 'created_at__lte' },
        { name: 'Created Before', prop: 'createdBefore', type: String, query: 'created_at__gte' }
      ])
    }
  }
}
</script>
