<template>
  <v-form
    v-model="valid"
    @submit.prevent="doneEditing"
  >
    <v-row
      class="text-center"
    >
      <v-col class="text-left">
        <router-link
          :to="{ name: 'hedge', params: { id: allocation.hedge } }"
        >
          <v-btn
            small
            text
          >
            {{ allocation.hedge }}
          </v-btn>
        </router-link>
        <small>{{ allocation.hedge_code }}</small>
        <v-btn
          class="ml-1"
          x-small
          text
          @click="$store.commit('hedgeBank/setExpandedHedge', allocation.hedge)"
        >
          expand on right
        </v-btn>
      </v-col>
      <v-col
        v-for="(metal, index) in metals"
        :key="index"
        :class="metal.style"
      >
        <span
          v-if="!editing"
        >
          {{ formatMetal(metal.toz) }}
        </span>
        <v-text-field
          dense
          v-else
          type="Number"
          :value="metal.toz"
          @input="metalChange(metal.metal, $event)"
          :loading="loading"
          :rules="makeAllocationRules(metal.metal)"
        />
      </v-col>
      <v-col>
        <!--edit icon-->
        <v-btn
          v-if="!editing"
          :disabled="isComplete"
          icon
          color="primary"
          @click="editing = true"
        >
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
        <!--done editing-->
        <v-btn
          v-else
          :disabled="!valid"
          icon
          color="success"
          @click="doneEditing"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <!--delete line-->
        <v-btn
          :disabled="!editing"
          class="hidden"
          color="error"
          icon
          @click="deleteDialog = true"
        >
          <v-icon
            v-if="editing"
          >
            mdi-delete-outline
          </v-icon>
          <v-avatar
            size="24"
            v-else-if="allocation.status === 'FIN'"
            color="primary"
          >
            <span class="white--text">F</span>
          </v-avatar>
        </v-btn>
        <base-dialog
          v-model="deleteDialog"
          headline-text="Delete this allocation?"
          message-text="Please confirm that you would like to delete the allocation."
          decline-text="decline"
          accept-text="accept"
          @accept="deleteAllocation(allocation)"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LotAllocation',
  props: {
    lot: {
      type: Object,
      required: true
    },
    allocation: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      valid: true,
      deleteDialog: false,
      pt: null,
      pd: null,
      rh: null,
      editing: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters('hedgeBank', [
      'getHedges'
    ]),
    // gets the hedge based on the allocation listed
    hedge () {
      if (this.allocation && this.allocation.hedge) {
        const hedges = this.$store.getters['hedgeBank/getHedges']
        const hedge = hedges.find(hedge => hedge.id === this.allocation.hedge)
        if (hedge) return hedge
      }
      return null
    },
    isComplete () {
      return this.lot.status === 'CO'
    },
    metals: function () {
      return [
        {
          metal: 'pt',
          style: { 'pt-color': true },
          toz: this.allocation.pt
        },
        {
          metal: 'pd',
          style: { 'pd-color': true },
          toz: this.allocation.pd
        },
        {
          metal: 'rh',
          style: { 'rh-color': true },
          toz: this.allocation.rh
        }
      ]
    }
  },
  methods: {
    ...mapActions('hedgeBank', [
      'updateAllocation',
      'deleteAllocation'
    ]),
    makeAllocationRules (key) {
      const possibleLotToz = Number(this.lot[`remaining_${key}`]) + Number(this.allocation[key])
      const possibleHedgeToz = Number(this.hedge[`${key}_estimated_toz`]) + Number(this.allocation[key])
      return [
        // (value) => !this.hedge || value <= Number(this.hedge[`${key}_estimated_toz`]) ||
        //   `Exceeds ${this.hedge[`${key}_estimated_toz`]} oz of available hedge`,
        (value) => possibleLotToz >= value || `Exceeds allowed lot allocation ${possibleLotToz}`,
        (value) => possibleHedgeToz >= value || `Exceeds allowed hedge allocation ${possibleHedgeToz}`,
        (value) => isNaN(value) || value >= 0 || 'value may not be negative'
      ]
    },
    doneEditing () {
      if (!this.valid) return
      let payload = { }
      if (this.pt !== null) payload['pt'] = this.pt
      if (this.pd !== null) payload['pd'] = this.pd
      if (this.rh !== null) payload['rh'] = this.rh
      if (!Object.keys(payload).length) {
        this.editing = false
        return
      }
      // Preform the allocation update
      this.updateAllocation({ allocation: this.allocation, payload })
        .then(() => { this.editing = false })
        .catch(() => {
          this.$store.commit('setSnackbarError', 'Allocation Failed -Getting Fresh Data-')
          this.$store.dispatch('hedgeBank/fetchManageable')
        })
    },
    metalChange (metal, payload) {
      this[metal] = payload
    },
    formatMetal (toz) {
      if (Number(toz) === 0) return '--'
      return toz
    }
  }
}
</script>
