<template>
  <unauthorized
    :authorized="$store.getters.can('view-smelters')"
    message="Unauthorized to view this page"
  >
    <!-- Skeleton Loader -->
    <smelter-view-skeleton
      v-if="loading"
    />
    <v-container v-else-if="!loading && smelter">
      <url-breadcrumbs
        v-if="smelter"
        :items="[
          ['smelter list', { name: 'smelter-list' }],
          [`smelter #${smelter.id}`]
        ]"
      />
      <v-row>
        <v-col>
          <h1 class="title">
            {{ `${smelter.name}` }}
            <span class="subtitle-1">
              ({{ smelter.code }})
            </span>
          </h1>
          <v-chip
            v-if="!smelter.is_active"
            small
          >
            Inactive
          </v-chip>
          <v-chip
            v-else
            dark
            small
            color="success"
          >
            Active
          </v-chip>
        </v-col>
      </v-row>
      <v-toolbar
        dense
        flat
      >
        <user-upload-dialog
          class="d-inline mr-3"
          :file-links="fileLinks"
        />
        <v-spacer />
        <v-btn
          :disabled="!$store.getters.can('edit-smelters')"
          small
          color="primary"
          :to="{ name: 'edit-smelter' }"
        >
          Edit
        </v-btn>
      </v-toolbar>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-row>
            <v-col col="12">
              <smelter-overview-card
                :smelter="smelter"
              />
            </v-col>
          </v-row>
          <v-row>
            <!-- Terms -->
            <v-col cols="12">
              <smelter-terms-card
                :smelter="smelter"
              />
            </v-col>
          </v-row>
        </v-col>
        <!-- Contacts -->
        <v-col
          :class="{'pt-6': $vuetify.breakpoint.lgAndUp }"
          lg="6"
        >
          <contacts-list-card
            :contacts="smelter.contacts_details"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col cols="12">
          <p>Failed to retrieve smelter #{{ $route.params.id }}</p>
        </v-col>
      </v-row>
    </v-container>
  </unauthorized>
</template>

<script>
import fileLinks from '@/helpers/fileLinks'
import ContactsListCard from '@/base/components/ContactsListCard'
import UserUploadDialog from '@/base/components/UserUploadDialog'
import axios from 'axios'
import SmelterTermsCard from '@/views/Smelter/components/SmelterTermsCard'
import SmelterViewSkeleton from '@/views/Smelter/components/SmelterViewSkeleton'
import SmelterOverviewCard from '@/views/Smelter/components/SmelterOverviewCard'

export default {
  name: 'Smelter',
  components: {
    SmelterOverviewCard,
    SmelterViewSkeleton,
    SmelterTermsCard,
    ContactsListCard,
    UserUploadDialog
  },
  data: () => ({
    loading: false,
    smelter: false
  }),
  methods: {
    /**
     * Fetch a smelter by its id
     * @param {Number} id - id of the smelter to get
     */
    async fetchSmelter () {
      try {
        const res = await axios.get(`/api/smelters/${this.$route.params.id}/`)
        this.smelter = res.data
      } catch (err) {
        this.$store.commit('setSnackbarError', `An error occurred while trying to fetch smelter ${this.$route.params.id}`, { root: true })
      }
    }
  },
  computed: {
    // Get links to this smelter's uploads
    fileLinks () {
      return fileLinks.smelterFileLinks(this.smelter)
    }
  },
  async mounted () {
    this.loading = true
    await this.fetchSmelter(this.$route.params.id)
    this.loading = false
  }
}
</script>
