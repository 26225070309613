<template>
  <v-dialog
    v-model="filterDialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <text-fab
        v-on="on"
        bottom
        right
        fixed
        icon-text="mdi-filter"
        color="primary"
      >
        Filter
      </text-fab>
    </template>
    <v-card class="pa-4">
      <v-card-title>
        Filters
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <v-autocomplete
              label="Lot Type"
              v-model="lotType"
              item-text="text"
              item-value="value"
              return-object
              :items="lotTypeList"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="filterDialog = false"
        >
          cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="applyFilter"
        >
          accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'FilterDialog',
  data: () => ({
    lotType: null,
    division: null,
    location: null,
    filterDialog: false,
    purchaseStatus: null
  }),
  computed: {
    ...mapGetters({
      lotTypes: 'lotStore/lotTypes',
      getLotType: 'currentPurchases/getLotType'
    }),
    lotTypeList () {
      return [ { text: 'All Types', value: null }, ...this.lotTypes ]
    }
  },
  methods: {
    ...mapMutations('currentPurchases', [
      'selectLotType'
    ]),
    applyFilter () {
      this.selectLotType(this.lotType)
      this.filterDialog = false
    }
  },
  mounted () {
    this.lotType = this.getLotType
  }
}
</script>
