<template>
  <div>
    <div
      v-if="canShow"
      :class="color"
    >
      {{ available | toFixed | addCommas }}/{{ initial | toFixed | addCommas }}
    </div>
    <div v-else>
      --
    </div>
  </div>
</template>

<script>
export default {
  name: 'HedgeDataOzColumn',
  props: {
    color: {
      type: Object,
      default: () => {}
    },
    canShow: {
      type: Boolean,
      required: true
    },
    initial: {
      type: String,
      required: true
    },
    available: {
      type: String,
      required: true
    }
  }
}
</script>
