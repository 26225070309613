<template>
  <v-card>
    <v-card-title>
      Notes/Memo
    </v-card-title>
    <v-card-text>
      {{ memo || '--' }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'HedgeNotesSection',
  props: {
    memo: {
      type: String,
      required: true
    }
  }
}
</script>
