<template>
  <parent-tabs :tabs="filterTabs()" />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HedgeParent',
  methods: {
    filterTabs () {
      return this.pageTabs.reduce((accu, i) => {
        if (i.can) accu.push(i)
        return accu
      }, [])
    }
  },
  computed: {
    ...mapGetters({
      can: 'can'
    }),
    pageTabs () {
      return [
        { id: 1, title: 'List', route: '/hedge-list', can: this.can('view-lots') },
        { id: 2, title: 'Create Hedge', route: '/create-hedge', can: this.can('create-lots') },
        { id: 3, title: 'Lot Allocations', route: '/current-allocations', can: this.can('create-lots') },
        // { id: 4, title: 'Check-In Allocations', route: '/check-in-allocations', can: this.can('create-lots') },
        { id: 4, title: 'Current Purchases', route: '/current-purchases', can: this.can('view-current-purchases') }
      ]
    }
  }
}
</script>
