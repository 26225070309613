<!--
A form input component for the Hedge Report Filters
-->
<template>
  <v-dialog
    v-model="filterDialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        fab
        bottom
        right
        fixed
        color="primary"
      >
        <v-icon>
          mdi-collage
        </v-icon>
      </v-btn>
    </template>
    <v-sheet
      class="pa-4"
      v-if="!loading"
    >
      <v-row>
        <v-card-title>
          Filters
        </v-card-title>
        <v-spacer />
        <v-btn
          icon
          @click="filterDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-card-text>
        <!-- Autocomplete Filters -->
        <div
          v-for="f in filters.filter(f => f.type === 'autocomplete')"
          :key="f.index"
        >
          <search-autocomplete
            v-model="f.itemValue"
            :label="f.label"
            :params="{ page_size: 0, fields: 'name', ordering: 'name' }"
            :format-entry="x => ({ friendlyName: x.name })"
            :api-path="f.path"
            :clearable="f.clearable"
            item-value="name"
            @input="f.itemValue === undefined ? f.itemValue = null : f.itemValue"
          />
        </div>
        <!-- Select Filters -->
        <div
          v-for="f in filters.filter(f => f.type === 'select')"
          :key="f.index"
        >
          <v-select
            :label="f.label"
            v-model="f.itemValue"
            :items="$store.getters[f.itemGetter]"
            item-value="value"
            :clearable="f.clearable"
            @input="f.itemValue === undefined ? f.itemValue = null : f.itemValue"
          />
        </div>
        <!-- Date Filters -->
        <div
          v-for="f in filters.filter(f => f.type === 'date')"
          :key="f.index"
        >
          <date-input
            v-model="f.itemValue"
            :label="f.label"
            @input="f.itemValue === undefined ? f.itemValue = null : f.itemValue"
          />
        </div>
      </v-card-text>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="red darken-2"
            text
            @click="filterDialog = false"
          >
            cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="handleFilters"
          >
            Search
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SearchAutocomplete from '@/base/components/SearchAutocomplete'
export default {
  name: 'HedgeReportFilters',
  components: {
    SearchAutocomplete
  },
  data: () => ({
    filterDialog: false,
    filters: null,
    loading: true
  }),
  methods: {
    ...mapMutations('hedgeStore', [
      'setReportFilters',
      'setReportHedgePage'
    ]),
    // set filters to store
    handleFilters () {
      this.setReportHedgePage(1)
      this.setReportFilters(this.filters)
      this.$emit('fetchHedges')
      this.filterDialog = false
    },
    // create a copy so state doesn't get mutated until user submits
    setFiltersLocally () {
      this.filters = JSON.parse(JSON.stringify(this.getReportFilters))
      this.loading = false
    }
  },
  computed: {
    ...mapGetters('hedgeStore', [
      'getReportFilters'
    ])
  },
  mounted () {
    this.setFiltersLocally()
  }
}
</script>
