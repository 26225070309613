<template>
  <unauthorized
    :authorized="$store.getters.can('view-lots')"
    message="Unauthorized to view Hedges"
  >
    <app-loading-indicator
      v-if="this.loading"
    />
    <template v-else>
      <UsdForcedAlert />
      <v-row>
        <v-col class="text-center">
          <span class="subtitle-1">
            {{ getSmelter && getSmelter.name }} / {{ getLotType && getLotType.text }}
          </span>
          <br>
          <v-btn
            small
            outlined
            @click="hedgeDialog = true"
          >
            Export
            <v-icon
              class="pl-1"
            >
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
          <hedge-allocation-export-view
            v-if="hedgeDialog"
            v-model="hedgeDialog"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :cols="12"
          :md="6"
        >
          <allocations-lots />
        </v-col>
        <v-col
          :cols="12"
          :md="6"
        >
          <allocations-hedges />
        </v-col>
      </v-row>
      <allocation-filter-dialog />
    </template>
  </unauthorized>
</template>

<script>
import AllocationsLots from './components/AllocationsLots'
import AllocationsHedges from './components/AllocationsHedges'
import AllocationFilterDialog from './components/AllocationFilterDialog'
import { mapActions, mapGetters } from 'vuex'
import HedgeAllocationExportView from './HedgeAllocationExportView'
import UsdForcedAlert from '@/components/UsdForcedAlert.vue'

export default {
  name: 'Allocations',
  components: {
    UsdForcedAlert,
    HedgeAllocationExportView,
    AllocationFilterDialog,
    AllocationsLots,
    AllocationsHedges
  },
  data: () => ({
    hedgeDialog: false,
    loading: true,
    fab: false
  }),
  computed: {
    ...mapGetters('hedgeBank', [
      'getLotType',
      'getSmelter',
      'getFilterableYards',
      'getYardFilter'
    ])
  },
  methods: {
    ...mapActions('hedgeBank', [
      'fetchManageable'
    ]),
    fetchAppData () {
      return this.fetchManageable()
    }
  },
  mounted () {
    this.fetchAppData().then(() => { this.loading = false })
  }
}
</script>

<style>
.split-screen {
  height: 80vh;
  width: 50vw;
  overflow-y: scroll;
}
</style>
