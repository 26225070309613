<template>
  <v-card v-if="$store.getters.can('edit-contacts')">
    <v-card-title>
      Contacts
    </v-card-title>
    <v-list v-if="contactsUpdated && contactsUpdated.length">
      <v-card
        flat
        v-for="(contact, index) in contactsUpdated"
        :key="contact.id"
      >
        <v-card-text>
          <v-list-item
            :key="contact.id"
            three-line
          >
            <v-list-item-content>
              <ValidationProvider
                rules="required|max:72"
                v-slot="{ errors }"
                immediate
              >
                <v-text-field
                  label="Title (e.g. 'Office Manager')"
                  v-model="contact.title"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider
                immediate
                rules="required|max:72"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="First Name"
                  v-model="contact.first_name"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider
                immediate
                rules="required|max:72"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Last Name"
                  v-model="contact.last_name"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider
                rules="max:40"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Phone Number"
                  v-model="contact.phone_number"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <ValidationProvider
                rules="email"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Email"
                  v-model="contact.email"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <div class="text-right">
                <confirm-button
                  color="accent"
                  text="Delete"
                  @accept="deleteContact(index)"
                >
                  This will permanently delete the contact
                </confirm-button>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-title>No Contacts</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="mx-3"
        color="primary"
        small
        @click="createContact"
      >
        Create Contact
      </v-btn>
    </v-card-actions>
  </v-card>
  <div v-else>
    Unauthorized to edit contacts
  </div>
</template>

<script>
import ConfirmButton from '@/base/components/ConfirmButton'

export default {
  name: 'ContactsListEditorCard',
  components: {
    ConfirmButton
  },
  props: {
    contacts: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    contactsUpdated: []
  }),
  watch: {
    /**
     * On component entry, create a local copy of the contacts prop
     * User input results in the new object being updated and emitted back to the parent
     */
    contacts: {
      handler () {
        this.contactsUpdated = this.contacts
      },
      immediate: true
    },
    /**
     * When a user's input results in a change to contactsUpdated,
     * send the updated contactsUpdated object to the parent component
     * @param contactsUpdated {Object} Contacts object containing user's updates
     */
    contactsUpdated: {
      handler () {
        this.$emit('contacts-updated', this.contactsUpdated)
      },
      deep: true
    }
  },
  methods: {
    /**
     * Add an empty contact to the contactsUpdated array
     */
    createContact () {
      this.contactsUpdated.push({
        title: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        email: ''
      })
    },
    /**
     * Remove a contact from the contactsUpdated list by array index
     */
    deleteContact (contactIndex) {
      this.contactsUpdated.splice(contactIndex, 1)
    }
  }
}
</script>
