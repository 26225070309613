<template>
  <div class="text-center ma-3">
    <v-btn>
      <download-excel
        :fields="excelHeaders"
        :name="titleWithDate"
        worksheet="Sheet1"
        :data="formattedData"
        :footer="[`Total Check-Ins - ${checkIns.length}`, `Total Pieces - ${totalCounts}`]"
      >
        Export
        <v-icon class="pl-2">
          mdi-microsoft-excel
        </v-icon>
      </download-excel>
    </v-btn>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'ExportCurrentPurchases',
  props: {
    checkIns: { type: Array, required: true }
  },
  data: () => ({
    excelHeaders: {
      'Created': 'created',
      'Completed': 'completed',
      'Check-In ID': 'id',
      'Check-In Status': 'status',
      'Purchase ID': 'purchase',
      'Intake ID': 'intake',
      'Current Location': 'intake',
      'ETA': 'eta',
      'Buyer': 'buyer',
      'Yard': 'yard',
      'Lot Type': 'lot_type',
      'OEM': 'oem_count',
      'Aftermarket': 'am_count',
      'Diesel': 'ds_count',
      'Bead': 'bd_count',
      'DPF': 'dp_count',
      'ImportFoil': 'if_count',
      'DomesticFoil': 'df_count',
      'GN': 'gn_count',
      'Total Pieces': 'total_count'
    }
  }),
  computed: {
    titleWithDate () {
      let today = moment()._d.toISOString().split('T')[0]
      return `CurrentPurchases${today}`
    },
    // Formats the data to match the table headers values names
    formattedData () {
      let formattedCheckIns
      if (this.checkIns.length > 0) {
        formattedCheckIns = this.checkIns.map((ci) => {
          const created = ci.created_at ? ci.created_at.split('T')[0] : ''
          const completed = ci.completed_at ? ci.completed_at.split('T')[0] : ''
          return {
            id: ci.id,
            intake: ci.purchase && ci.purchase.intake && ci.purchase.intake.id,
            current_location: ci.current_location.name,
            purchase: ci.purchase.id,
            created,
            completed,
            eta: ci.active_transit ? ci.active_transit.delivery_date.split('T')[0] : '',
            buyer: ci.created_by && ci.created_by.username,
            yard: ci.yard_name,
            lot_type: ci.purchase.designation,
            oem_count: ci.total_counts.OM,
            am_count: ci.total_counts.AM,
            if_count: ci.total_counts.IF,
            df_count: ci.total_counts.DF,
            ds_count: ci.total_counts.DS,
            dp_count: ci.total_counts.DP,
            bd_count: ci.total_counts.BD,
            gn_count: ci.total_counts.GN,
            total_count: ci.total_counts.total,
            status: ci.status,
            purchase_status: ci.purchase.status
          }
        })
      }
      return formattedCheckIns
    },
    // Returns the total number of pieces included
    totalCounts () {
      let counts = this.checkIns.reduce((accu, ci) => {
        accu += ci.total_counts.total
        return accu
      }, 0)
      return counts.toString()
    }
  }
}
</script>
