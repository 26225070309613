<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <lot-name-field v-model="lotNameFilter" />
    </v-col>
  </v-row>
</template>

<script>
import LotNameField from '@/views/Hedges/Allocations/components/Inputs/LotNameField.vue'

export default {
  name: 'AllocationLotFilterFields',
  components: {
    LotNameField
  },
  computed: {
    lotNameFilter: {
      get () {
        return this.$store.getters['hedgeBank/getLotName']
      },
      set (value) {
        this.$store.commit('hedgeBank/setLotName', value)
      }
    }
  }
}
</script>
