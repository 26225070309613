<template>
  <v-card>
    <v-card-title>
      Designated Lots
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="lotTableHeaders"
        :items="lots"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'HedgeLots',
  data: () => ({
    lotTableHeaders: [
      { text: 'Name', value: 'name' }
    ]
  }),
  props: {
    lots: {
      type: Array,
      required: true
    }
  }
}
</script>
