<template>
  <v-text-field
    :value="value"
    dense
    label="Hedge ID"
    hint="Filter Hedges by ID"
    clearable
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'HedgeIdField',
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>
