<template>
  <v-card>
    <v-card-title>
      Terms
    </v-card-title>
    <v-card-text>
      <v-row class="text-center">
        <v-col>
          <h4>Pt Return Rate</h4>
          <span class="pt-color">
            {{ smelter.pt_return_rate * 100 }}%
          </span>
        </v-col>
        <v-col>
          <h4>Pd Return Rate</h4>
          <span class="pd-color">
            {{ smelter.pd_return_rate * 100 }}%
          </span>
        </v-col>
        <v-col>
          <h4>Rh Return Rate</h4>
          <span class="rh-color">
            {{ smelter.rh_return_rate * 100 }}%
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Treatment Charge per Pound
            <info-tooltip>
              The actual treatment charge for each lot gets entered when the assay results (shipment summary)
              are entered either manually or uploaded.
            </info-tooltip>
          </h4>
          {{ smelter.treatment_charge_per_lb | filterNA }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Value Days
            <info-tooltip>
              (aka Return Date) This field is for the number of days after the lot is received by the smelter or if<br>
              we elect to be present at time of sampling. We also enter the Value Date (also known as Return Date)<br>
              for each individual lot when we either manually enter or upload the assay results (shipment summary).
            </info-tooltip>
          </h4>
          {{ smelter.value_days | filterNA }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Expected Result Return (Days)
            <info-tooltip>
              This date is the date the smelter has told us we should expect assay results and close it is to the<br>
              Assay Date (in the shipment summary when we receive the shipment summary results) for negotiation purposes.
            </info-tooltip>
          </h4>
          {{ smelter.expected_result_return | filterNA }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Finance Charge
            <info-tooltip>
              This is the Finance Charge that the Smelter is charging us... this charge will get entered when<br>
              I am doing the Final Lot Report after we have gotten paid by the smelter and if we got paid before the Value Date.
            </info-tooltip>
          </h4>
          {{ smelter.finance_charge * 100 | filterNA }}%
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Carbon Limit per Lot
            <info-tooltip>
              Some smelters only allow a certain percentage of pounds of carbon from the Net Dry Lbs per lot.
            </info-tooltip>
          </h4>
          {{ smelter.carbon_limit_per_lot * 100 | filterNA }}%
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Avg Carbon Content per {{ smelter.time_period_text }}
            <info-tooltip>
              Normally the smelter will require that we stay below a certain average percentage of carbon per a certain
              time period. For example Stillwater terms are at 1% average.<br> They would calculate this by dividing the
              total carbon content pounds of lots received by the smelter within a certain time period (for them it is
              currently at every calendar quarter)<br> by the total Net Dry LBS of that same time period and that will give
              us an Average Carbon Content %.
            </info-tooltip>
          </h4>
          {{ smelter.avg_carbon_content * 100 | filterNA }}%
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Max Carbon Lbs per {{ smelter.time_period_text }}
            <info-tooltip>
              As an example, Stillwater only allows up to 2% carbon of the Dry Net Lbs per lot,<br> but only up to 1500
              lbs of carbon for the quarter.
            </info-tooltip>
          </h4>
          {{ smelter.max_carbon_lbs_per_period | filterNA }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>
            Min Lbs to be Received by Smelter per {{ smelter.time_period_text }}
            <info-tooltip>
              The smelter has different Treatment Charges for us depending on how much material we send on a given
              period.<br> For example, if we send below 100,000 Net Dry Lbs on the previous quarter, the Treatment
              Charge will increase.
            </info-tooltip>
          </h4>
          {{ smelter.min_lbs_per_period | filterNA }}
        </v-col>
      </v-row>
      <v-row class="text-left">
        <v-col>
          <h4>
            Additional Terms
            <info-tooltip>
              to make additional notes or things to keep in mind regarding the smelter terms.
            </info-tooltip>
          </h4>
          {{ smelter.additional_terms | filterNA }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SmelterTermsCard',
  props: {
    smelter: {
      type: Object,
      required: true
    }
  }
}
</script>
