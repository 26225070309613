<template>
  <v-form
    class=""
    v-model="valid"
    @submit.prevent="addAllocation"
  >
    <v-row
      class="text-center"
    >
      <v-col>
        <v-select
          v-model="hedge"
          :items="availableHedges"
          item-text="friendlyName"
          return-object
          dense
          label="Hedge"
        />
      </v-col>
      <v-col>
        <v-text-field
          dense
          v-model="pt"
          type="Number"
          :loading="loading"
          :rules="makeAllocationRules('pt')"
        />
      </v-col>
      <v-col>
        <v-text-field
          dense
          v-model="pd"
          type="Number"
          :loading="loading"
          :rules="makeAllocationRules('pd')"
        />
      </v-col>
      <v-col>
        <v-text-field
          dense
          v-model="rh"
          type="Number"
          :loading="loading"
          :rules="makeAllocationRules('rh')"
        />
      </v-col>
      <v-col>
        <v-btn
          :loading="addingAllocation"
          :disabled="!hedge || !valid"
          @click="addAllocation"
          small
        >
          add {{ allocationType }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AddAllocation',
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      addingAllocation: false,
      valid: false,
      hedge: null,
      pt: null,
      pd: null,
      rh: null,
      loading: false
    }
  },
  watch: {
    hedge (hedge) {
      if (!hedge) return
      const maxOf = function (x, y) {
        x = Number(x)
        y = Number(y)
        const z = x > y ? y : x
        return z < 0 ? 0 : z
      }
      this.pt = maxOf(hedge.pt_estimated_toz, this.lot.remaining_pt)
      this.pd = maxOf(hedge.pd_estimated_toz, this.lot.remaining_pd)
      this.rh = maxOf(hedge.rh_estimated_toz, this.lot.remaining_rh)
    }
  },
  computed: {
    ...mapGetters('hedgeBank', [
      'getAllocations',
      'getHedges'
    ]),
    availableHedges () {
      let usedHedges = this.getAllocations.filter(a => a.lot === this.lot.id).map(a => a.hedge)
      usedHedges = this.getHedges.filter(h => !usedHedges.includes(h.id) && h.smelter === this.lot.smelter)
      usedHedges = usedHedges.map(hedge => {
        hedge.friendlyName = hedge.id
        if (Number(hedge.pt_estimated_toz) + Number(hedge.pd_estimated_toz) + Number(hedge.rh_estimated_toz) === 0) {
          hedge.friendlyName = hedge.friendlyName + ' (empty)'
        }
        return hedge
      })
      return usedHedges
    },
    allocationType () {
      if (this.lot.status === 'RC') return 'FIN'
      if (this.lot.status === 'CO') return 'FIN'
      return 'PRE'
    }
  },
  methods: {
    ...mapActions('hedgeBank', [
      'postAllocation'
    ]),
    makeAllocationRules (key) {
      return [
        (value) => !this.hedge || value <= Number(this.hedge[`${key}_estimated_toz`]) ||
            `Exceeds ${this.hedge[`${key}_estimated_toz`]} oz of available hedge`,
        (value) => Number(this.lot[`remaining_${key}`]) >= value ||
          `Exceeds ${this.lot[`remaining_${key}`]} oz of available lot`,
        (value) => isNaN(value) || value >= 0 || 'value may not be negative'
      ]
    },
    async addAllocation () {
      if (!this.valid) return
      // prevent multiple requests
      this.addingAllocation = true
      let allocation = {}
      if (this.pt) allocation.pt = this.pt
      if (this.pd) allocation.pd = this.pd
      if (this.rh) allocation.rh = this.rh
      if (Object.keys(allocation).length === 0) {
        this.$store.commit('setSnackbarError', 'One Metal allocation is required')
        this.addingAllocation = false
        return
      }
      allocation.lotId = this.lot.id
      allocation.hedgeId = this.hedge.id
      allocation.status = this.allocationType
      try {
        await this.postAllocation(allocation)
      } catch (err) {
        this.$store.commit('setSnackbarError', 'Unable to Create Allocation -Getting Fresh Data-')
        await this.$store.dispatch('hedgeBank/fetchManageable')
      }
      this.hedge = null
      this.pt = null
      this.pd = null
      this.rh = null
      this.addingAllocation = false
    }
  }
}
</script>
