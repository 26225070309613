<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader type="heading" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader type="table-row" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SmelterViewSkeleton'
}
</script>
