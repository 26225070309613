<template>
  <v-card>
    <v-card-subtitle>Overview</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <h4>
            Phone Number
          </h4>
          {{ smelter.phone_number | filterNA }}
        </v-col>
        <v-col cols="12">
          <h4>
            Email Address
          </h4>
          {{ smelter.email | filterNA }}
        </v-col>
        <v-col cols="12">
          <h4>
            Address
          </h4>
          {{ smelter.address | filterNA }}
        </v-col>
        <v-col cols="12">
          <h4>
            Notes
          </h4>
          {{ smelter.notes | filterNA }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SmelterOverviewCard',
  props: {
    smelter: {
      type: Object,
      required: true
    }
  }
}
</script>
