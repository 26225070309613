var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('unauthorized',{attrs:{"authorized":_vm.$store.getters.can('edit-smelters'),"message":"Unauthorized to view this page"}},[(_vm.loading)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"heading"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table-row"}})],1)],1)],1):(!_vm.loading && _vm.smelter)?_c('v-container',[(_vm.smelter)?_c('url-breadcrumbs',{attrs:{"items":[
        ['smelter list', { name: 'smelter-list' }],
        [("smelter #" + (_vm.smelter.id)), { name: 'smelter', params: { id: this.$route.params.id }}],
        ["Edit"]
      ]}}):_vm._e(),(_vm.changedCounter > 0)?_c('v-alert',{attrs:{"dark":"","color":"primary"}},[_vm._v(" You have unsaved changes ")]):_vm._e(),_c('v-row',[_c('v-col',[_c('h1',{staticClass:"title"},[_vm._v(" Editing Smelter "+_vm._s(("" + (_vm.smelter.name)))+" "),_c('span',{staticClass:"subtitle-1"},[_vm._v(" (code "+_vm._s(_vm.smelter.code)+") ")])])])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var errors = ref.errors;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('user-upload-dialog',{staticClass:"d-inline mr-3",attrs:{"file-links":_vm.fileLinks}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('smelter-overview-edit-card',{attrs:{"smelter-overview":_vm.smelterOverview},on:{"overviewUpdated":function($event){return _vm.updateOverview($event)}}}),_c('smelter-terms-edit-card',{attrs:{"smelter-terms":_vm.smelterTerms},on:{"termsUpdated":function($event){return _vm.updateTerms($event)}}})],1),_c('v-col',{staticClass:"pt-0",class:{'pt-6': _vm.$vuetify.breakpoint.lgAndUp },attrs:{"cols":"12","lg":"5"}},[(_vm.contactsDetails !== null)?_c('contacts-list-editor-card',{attrs:{"contacts":_vm.contactsDetails},on:{"contacts-updated":function($event){return _vm.updateContacts($event)}}}):_vm._e()],1)],1),_c('v-btn',{attrs:{"disabled":invalid || _vm.changedCounter <= 0,"fixed":"","bottom":"","right":"","fab":"","color":"primary","type":"submit"},on:{"click":_vm.updateSmelter}},[_c('v-icon',[_vm._v(" mdi-content-save ")])],1)],1)]}}])})],1):_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Failed to initialize form for Smelter #"+_vm._s(_vm.$route.params.id))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }