var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-subtitle',[_vm._v("Terms")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"max_value:100|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"PT Rate","type":"number","append-icon":"mdi-percent","error-messages":errors},model:{value:(_vm.ptReturnRate),callback:function ($$v) {_vm.ptReturnRate=$$v},expression:"ptReturnRate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"max_value:100|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"PD Rate","type":"number","append-icon":"mdi-percent","error-messages":errors},model:{value:(_vm.pdReturnRate),callback:function ($$v) {_vm.pdReturnRate=$$v},expression:"pdReturnRate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"max_value:100|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"RH Rate","type":"number","append-icon":"mdi-percent","error-messages":errors},model:{value:(_vm.rhReturnRate),callback:function ($$v) {_vm.rhReturnRate=$$v},expression:"rhReturnRate"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max_value:9999|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Treatment Charge per Pound","type":"number","append-icon":"mdi-currency-usd","error-messages":errors},model:{value:(_vm.formData.treatmentRate),callback:function ($$v) {_vm.$set(_vm.formData, "treatmentRate", $$v)},expression:"formData.treatmentRate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max_value:100|decimal:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Finance Charge","type":"number","append-icon":"mdi-percent","error-messages":errors},model:{value:(_vm.financePercent),callback:function ($$v) {_vm.financePercent=$$v},expression:"financePercent"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max_value:100|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Avg Carbon Content","type":"number","append-icon":"mdi-percent","error-messages":errors},model:{value:(_vm.avgCarbonContent),callback:function ($$v) {_vm.avgCarbonContent=$$v},expression:"avgCarbonContent"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"max_value:100|decimal:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Carbon Limit per Lot","type":"number","append-icon":"mdi-percent","error-messages":errors},model:{value:(_vm.carbonLimitPerLot),callback:function ($$v) {_vm.carbonLimitPerLot=$$v},expression:"carbonLimitPerLot"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"decimal:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Value Days","type":"number","error-messages":errors},model:{value:(_vm.formData.valueDays),callback:function ($$v) {_vm.$set(_vm.formData, "valueDays", $$v)},expression:"formData.valueDays"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"decimal:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"px-4",attrs:{"label":"Time Period","item-text":"name","item-value":"value","items":_vm.timePeriodOptions,"error-messages":errors},model:{value:(_vm.formData.timePeriod),callback:function ($$v) {_vm.$set(_vm.formData, "timePeriod", $$v)},expression:"formData.timePeriod"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Expected Result Return (Days)","type":"number","error-messages":errors},model:{value:(_vm.formData.expectedResultReturn),callback:function ($$v) {_vm.$set(_vm.formData, "expectedResultReturn", $$v)},expression:"formData.expectedResultReturn"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Max Carbon Lbs per Period","type":"number","error-messages":errors},model:{value:(_vm.formData.maxCarbonLbsPerPeriod),callback:function ($$v) {_vm.$set(_vm.formData, "maxCarbonLbsPerPeriod", $$v)},expression:"formData.maxCarbonLbsPerPeriod"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"px-4",attrs:{"label":"Min Lbs per Period","type":"number","error-messages":errors},model:{value:(_vm.formData.minLbsPerPeriod),callback:function ($$v) {_vm.$set(_vm.formData, "minLbsPerPeriod", $$v)},expression:"formData.minLbsPerPeriod"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"max:512"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"px-4",attrs:{"label":"Additional Terms","error-messages":errors},model:{value:(_vm.formData.additionalTerms),callback:function ($$v) {_vm.$set(_vm.formData, "additionalTerms", $$v)},expression:"formData.additionalTerms"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }