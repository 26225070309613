<template>
  <v-card
    elevation="2"
  >
    <v-container>
      <v-row>
        <v-col class="text-center py-1">
          Name
        </v-col>
        <v-col class="text-center py-1">
          Platinum
        </v-col>
        <v-col class="text-center py-1">
          Palladium
        </v-col>
        <v-col class="text-center py-1">
          Rhodium
        </v-col>
        <v-col class="text-center px-0" />
      </v-row>
      <v-row>
        <v-col class="text-center py-1">
          {{ isEstimated ? 'Est. Contained' : 'Contained' }}
        </v-col>
        <v-col class="text-center py-1">
          {{ contained.pt | toFixed(3) | trimDecimals | addCommas }} ctoz
        </v-col>
        <v-col class="text-center py-1">
          {{ contained.pd | toFixed(3) | trimDecimals | addCommas }} ctoz
        </v-col>
        <v-col class="text-center py-1">
          {{ contained.rh | toFixed(3) | trimDecimals | addCommas }} ctoz
        </v-col>
        <v-col class="text-center px-0" />
      </v-row>
      <v-row>
        <v-col class="text-center py-1">
          {{ isEstimated ? 'Est. Returnable' : 'Returnable' }}
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ returnable.rPt | toFixed(3) | trimDecimals | addCommas | filterZero }} rtoz
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ returnable.rPd | toFixed(3) | trimDecimals | addCommas | filterZero }} rtoz
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ returnable.rRh | toFixed(3) | trimDecimals | addCommas | filterZero }} rtoz
        </v-col>
        <v-col class="text-center px-0" />
      </v-row>
      <v-divider />
      <v-row v-if="allocations.length">
        <v-col class="text-center py-1">
          Allocated
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ allocationTotals.pt | toFixed(3) | trimDecimals | addCommas }} rtoz
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ allocationTotals.pd | toFixed(3) | trimDecimals | addCommas }} rtoz
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ allocationTotals.rh | toFixed(3) | trimDecimals | addCommas }} rtoz
        </v-col>
        <v-col class="text-center px-0" />
      </v-row>
      <v-row v-if="allocations.length">
        <v-col class="text-center py-1">
          Weighted Avg $
        </v-col>
        <v-col class="text-center py-1 pt-color">
          $ {{ averageRates.pt | toFixed(2) | trimDecimals | addCommas }} / toz
        </v-col>
        <v-col class="text-center py-1 pd-color">
          $ {{ averageRates.pd | toFixed(2) | trimDecimals | addCommas }} / toz
        </v-col>
        <v-col class="text-center py-1 rh-color">
          $ {{ averageRates.rh | toFixed(2) | trimDecimals | addCommas }} / toz
        </v-col>
        <v-col class="text-center px-0" />
      </v-row>
      <v-divider v-if="!!allocations.length" />
      <lot-allocation
        v-for="alloc in allocations"
        :key="alloc.id"
        :allocation="alloc"
        :lot="lot"
      />
      <!--Only allowed if not complete-->
      <add-allocation
        v-if="lot.status !== 'CO'"
        :lot="lot"
      />
      <v-row class="text-center">
        <v-col>
          Inventory
          <br>
          {{ lot.inventory_name }}
        </v-col>
        <v-col>
          Lot Value
          <br>
          $ {{ lotValue | toFixed(2) | trimDecimals | addCommas }}
        </v-col>
        <v-col>
          <!--PPM Calculator-->
          Hedge Group
          <br>
          {{ lot.ppm_calculator_name }}
        </v-col>
        <v-col>
          <v-select
            :disabled="allocations.length !== 0"
            :value="lot.smelter"
            :items="getSmelters"
            item-text="name"
            item-value="id"
            return-object
            dense
            label="Smelter"
            @change="switchSmelter"
          />
        </v-col>
      </v-row>
      <lot-expand-buttons
        :lot="lot"
        :key="lot.url"
        :allocations="allocations"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LotAllocation from './LotAllocation'
import AddAllocation from './AddAllocation'
import LotExpandButtons from './LotExpandButtons'

export default {
  name: 'LotExpand',
  components: { LotExpandButtons, AddAllocation, LotAllocation },
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('hedgeBank', [
      'getLotAllocations',
      'getAllocations',
      'getSmelters'
    ]),
    isEstimated () {
      return !this.lot.shipment_summary_lot
    },
    allocations () {
      return this.getAllocations
        .filter(a => a.lot === this.lot.id)
        .sort((a, b) => b.hedge > a.hedge ? 1 : -1)
    },
    lotValue () {
      const total = this.allocations.reduce((total, a) => {
        total += Number(a.pt) * a.pt_rate
        total += Number(a.pd) * a.pd_rate
        total += Number(a.rh) * a.rh_rate
        return total
      }, 0)
      return total
    },
    allocationTotals () {
      return this.allocations.reduce((c, a) => {
        c.pt += Number(a.pt)
        c.pd += Number(a.pd)
        c.rh += Number(a.rh)
        return c
      }, { pt: 0, pd: 0, rh: 0 })
    },
    averageRates () {
      const accum = this.allocations.reduce((c, a) => {
        c.ptDollars += Number(a.pt) * Number(a.pt_rate)
        c.ptSum += Number(a.pt)
        c.pdDollars += Number(a.pd) * Number(a.pd_rate)
        c.pdSum += Number(a.pd)
        c.rhDollars += Number(a.rh) * Number(a.rh_rate)
        c.rhSum += Number(a.rh)
        return c
      }, { ptDollars: 0, ptSum: 0, pdDollars: 0, pdSum: 0, rhDollars: 0, rhSum: 0 })
      return {
        pt: accum.ptDollars / accum.ptSum,
        pd: accum.pdDollars / accum.pdSum,
        rh: accum.rhDollars / accum.rhSum
      }
    },
    contained () {
      let pt, pd, rh
      if (this.isEstimated) {
        pt = this.lot.estimated_pt
        pd = this.lot.estimated_pd
        rh = this.lot.estimated_rh
      } else {
        pt = this.lot.contained_pt
        pd = this.lot.contained_pd
        rh = this.lot.contained_rh
      }
      return { pt, pd, rh }
    },
    returnable () {
      let rPt, rPd, rRh
      if (this.isEstimated) {
        rPt = this.lot.estimated_returnable_pt
        rPd = this.lot.estimated_returnable_pd
        rRh = this.lot.estimated_returnable_rh
      } else {
        rPt = this.lot.returnable_pt
        rPd = this.lot.returnable_pd
        rRh = this.lot.returnable_rh
      }
      return { rPt, rPd, rRh }
    }
  },
  methods: {
    ...mapActions('hedgeBank', [
      'updateLot'
    ]),
    switchSmelter (smelter) {
      this.updateLot({
        lot: this.lot,
        payload: {
          smelter_id: smelter.id
        }
      })
    }
  }
}
</script>
