var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" Hedges "),_c('help-dialog',{attrs:{"headline-text":"About List of Hedges"}},[_c('h4',[_vm._v("Which hedges appear here?")]),_c('p',[_vm._v(" This list is populated by any hedge marked with a incomplete flag. ")]),_c('h4',[_vm._v("When are hedges Completed?")]),_c('p',[_vm._v(" Under normal conditions hedges will be automatically completed whenever a lot is marked complete. "),_c('em',[_vm._v(" This is accomplished by preforming a lookup against all of the lots attached hedges and marking any that are zeroed as complete. ")])]),_c('p',[_vm._v(" Alternatively hedges may be marked complete manually once it is zeroed. (Although this should not normally be required). ")]),_c('h4',[_vm._v(" When is a hedge Zeroed? ")]),_c('p',[_vm._v(" Hedges are considered zeroed when all oz are completely allocated with FINAL allocations. ")]),_c('h4',[_vm._v("Available vs Estimated")]),_c('ul',[_c('li',[_vm._v("Available refers to the remainder after deduction FINAL allocations")]),_c('li',[_vm._v("Estimated refers to the remainder after deducting FINAL and PRELIMINARY allocations")])])])],1),_c('allocation-header',{attrs:{"rows":_vm.headerRows}}),_c('v-container',[_c('allocation-hedge-filter-fields'),_c('v-data-table',{class:{'split-screen': !_vm.$vuetify.breakpoint.smAndDown, 'pt-6': true},staticStyle:{"cursor":"pointer"},attrs:{"mobile-breakpoint":500,"headers":_vm.tableHeaders,"items":_vm.dataTableRows,"expanded":_vm.expanded,"hide-default-footer":"","multi-sort":"","single-expand":"","disable-pagination":"","item-class":_vm.rowClasses,"search":_vm.filter},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expand},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'hedge', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.id)+" ")]),(item.is_complete)?_c('v-icon',[_vm._v(" mdi-lock ")]):_vm._e(),_c('br'),_c('small',[_vm._v(_vm._s(item.code))])]}},{key:"item.pt_estimated_toz",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-color text-center"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.pt_estimated_toz,3)))+" "),_c('br'),_c('small',[_vm._v("@ $"+_vm._s(_vm._f("addCommas")(_vm._f("trimDecimals")(_vm._f("toFixed")(item.pt_rate_per_toz,2))))+" / oz")])])]}},{key:"item.pd_estimated_toz",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pd-color text-center"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.pd_estimated_toz,3)))+" "),_c('br'),_c('small',[_vm._v("@ $"+_vm._s(_vm._f("addCommas")(_vm._f("trimDecimals")(_vm._f("toFixed")(item.pd_rate_per_toz,2))))+" / oz")])])]}},{key:"item.rh_estimated_toz",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"rh-color text-center"},[_vm._v(" "+_vm._s(_vm._f("addCommas")(_vm._f("toFixed")(item.rh_estimated_toz,3)))+" "),_c('br'),_c('small',[_vm._v("@ $"+_vm._s(_vm._f("addCommas")(_vm._f("trimDecimals")(_vm._f("toFixed")(item.rh_rate_per_toz,2))))+" / oz")])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" "),_c('br'),(item.expires_on)?_c('small',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.expires_on))+" ")]):_vm._e()])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-2",staticStyle:{"cursor":"auto"},attrs:{"colspan":_vm.tableHeaders.length}},[_c('hedge-expand',{attrs:{"hedge":item}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }