<template>
  <v-card>
    <v-card-title class="headline justify-center">
      Hedges
      <help-dialog
        headline-text="About List of Hedges"
      >
        <h4>Which hedges appear here?</h4>
        <p>
          This list is populated by any hedge marked with a incomplete flag.
        </p>
        <h4>When are hedges Completed?</h4>
        <p>
          Under normal conditions hedges will be automatically completed whenever a lot is marked complete.
          <em>
            This is accomplished by preforming a lookup against all of the lots attached hedges and marking any that are zeroed as complete.
          </em>
        </p>
        <p>
          Alternatively hedges may be marked complete manually once it is zeroed. (Although this should not normally be required).
        </p>
        <h4>
          When is a hedge Zeroed?
        </h4>
        <p>
          Hedges are considered zeroed when all oz are completely allocated with FINAL allocations.
        </p>
        <h4>Available vs Estimated</h4>
        <ul>
          <li>Available refers to the remainder after deduction FINAL allocations</li>
          <li>Estimated refers to the remainder after deducting FINAL and PRELIMINARY allocations</li>
        </ul>
      </help-dialog>
    </v-card-title>
    <allocation-header :rows="headerRows" />
    <v-container>
      <allocation-hedge-filter-fields />
      <v-data-table
        :mobile-breakpoint="500"
        :headers="tableHeaders"
        :items="dataTableRows"
        :expanded.sync="expanded"
        hide-default-footer
        multi-sort
        single-expand
        disable-pagination
        :class="{'split-screen': !$vuetify.breakpoint.smAndDown, 'pt-6': true}"
        style="cursor: pointer"
        :item-class="rowClasses"
        @click:row="expand"
        :search="filter"
      >
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{ name: 'hedge', params: { id: item.id } }"
          >
            {{ item.id }}
          </router-link>
          <v-icon v-if="item.is_complete">
            mdi-lock
          </v-icon>
          <br>
          <small>{{ item.code }}</small>
        </template>
        <template v-slot:item.pt_estimated_toz="{ item }">
          <div class="pt-color text-center">
            {{ item.pt_estimated_toz | toFixed(3) | addCommas }}
            <br>
            <small>@ ${{ item.pt_rate_per_toz | toFixed(2) | trimDecimals | addCommas }} / oz</small>
          </div>
        </template>
        <template v-slot:item.pd_estimated_toz="{ item }">
          <div class="pd-color text-center">
            {{ item.pd_estimated_toz | toFixed(3) | addCommas }}
            <br>
            <small>@ ${{ item.pd_rate_per_toz | toFixed(2) | trimDecimals | addCommas }} / oz</small>
          </div>
        </template>
        <template v-slot:item.rh_estimated_toz="{ item }">
          <div class="rh-color text-center">
            {{ item.rh_estimated_toz | toFixed(3) | addCommas }}
            <br>
            <small>@ ${{ item.rh_rate_per_toz | toFixed(2) | trimDecimals | addCommas }} / oz</small>
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div class="text-center">
            {{ item.created_at | formatDate }}
            <br>
            <small v-if="item.expires_on">
              {{ item.expires_on | formatDate }}
            </small>
          </div>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td
            :colspan="tableHeaders.length"
            class="pa-2"
            style="cursor: auto"
          >
            <hedge-expand :hedge="item" />
          </td>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import AllocationHeader from './AllocationHeader'
import HedgeExpand from './HedgeExpand'
import { mapGetters } from 'vuex'
import AllocationHedgeFilterFields from '@/views/Hedges/Allocations/components/Inputs/AllocationHedgeFilterFields.vue'
export default {
  name: 'AllocationsHedges',
  components: { AllocationHedgeFilterFields, HedgeExpand, AllocationHeader },
  data: () => ({
    filter: '',
    tableHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Platinum', value: 'pt_estimated_toz' },
      { text: 'Palladium', value: 'pd_estimated_toz' },
      { text: 'Rhodium', value: 'rh_estimated_toz' },
      { text: 'Created', value: 'created_at' }
    ],
    includePre: true,
    expanded: [],
    editingHedge: false,
    hedges: []
  }),
  watch: {
    getExpandedHedge (id) {
      let found = this.dataTableRows.find(x => x.id === id)
      if (found) {
        this.expanded = [found]
      } else if (id) {
        this.$store.commit('setSnackbarError', 'hedge is not present in hedge bank')
      }
    }
  },
  methods: {
    rowClasses (row) {
      const total = Number(row.pt_available_toz) + Number(row.pd_available_toz) + Number(row.rh_available_toz)
      if (total !== 0) return ''
      if (this.$vuetify.theme.dark) {
        return 'grey darken-3'
      } else {
        return 'grey lighten-4'
      }
    },
    expand (row) {
      this.$store.commit('hedgeBank/setExpandedHedge', '')
      this.expanded = (this.expanded.find(e => e.id === row.id)) ? [] : [row]
    }
  },
  computed: {
    ...mapGetters('hedgeBank', [
      'getHedges',
      'getExpandedHedge'
    ]),
    dataTableRows () {
      return this.getHedges
    },
    headerRows () {
      let rows = []
      // Hedged OZ INITIAL - FINAL
      const hedgedOz = this.getHedges.reduce((c, h) => {
        c.pt += Number(h.pt_available_toz)
        c.pd += Number(h.pd_available_toz)
        c.rh += Number(h.rh_available_toz)
        return c
      }, { pt: 0, pd: 0, rh: 0 })

      rows.push({ name: 'Available Oz',
        pt: hedgedOz.pt.toFixed(3),
        pd: hedgedOz.pd.toFixed(3),
        rh: hedgedOz.rh.toFixed(3),
        description: 'INITIAL_TOZ - FINAL'
      })

      // Available OZ INITIAL - FINAL - PRE
      const availOz = this.getHedges.reduce((c, h) => {
        c.pt += Number(h.pt_estimated_toz)
        c.pd += Number(h.pd_estimated_toz)
        c.rh += Number(h.rh_estimated_toz)
        return c
      }, { pt: 0, pd: 0, rh: 0 })
      rows.push({ name: 'Est. Available Oz',
        pt: availOz.pt.toFixed(3),
        pd: availOz.pd.toFixed(3),
        rh: availOz.rh.toFixed(3),
        description: 'INITIAL_TOZ - (FINAL + PRE)'
      })

      // Calculate average $
      const ozRate = this.getHedges.reduce((c, h) => {
        c.pt += (Number(h.pt_rate_per_toz) * Number(h.pt_available_toz))
        c.ptSum += Number(h.pt_available_toz)
        c.pd += (Number(h.pd_rate_per_toz) * Number(h.pd_available_toz))
        c.pdSum += Number(h.pd_available_toz)
        c.rh += (Number(h.rh_rate_per_toz) * Number(h.rh_available_toz))
        c.rhSum += Number(h.rh_available_toz)
        return c
      }, { pt: 0, ptSum: 0, pd: 0, pdSum: 0, rh: 0, rhSum: 0 })
      rows.push({ name: 'Average Rate Per Oz (Avail)',
        pt: this.$options.filters.currencyUSD(ozRate.pt / ozRate.ptSum),
        pd: this.$options.filters.currencyUSD(ozRate.pd / ozRate.pdSum),
        rh: this.$options.filters.currencyUSD(ozRate.rh / ozRate.rhSum),
        description: 'Weighted Average of (AVAILABLE_TOZ * VALUE) / AVAILABLE_TOZ'
      })

      // Calculate available value
      const availableValue = this.getHedges.reduce((c, h) => {
        c.pt += Number(h.pt_available_value)
        c.pd += Number(h.pd_available_value)
        c.rh += Number(h.rh_available_value)
        return c
      }, { pt: 0, pd: 0, rh: 0 })
      rows.push({ name: 'Total Value (Avail)',
        pt: this.$options.filters.currencyUSD(availableValue.pt),
        pd: this.$options.filters.currencyUSD(availableValue.pd),
        rh: this.$options.filters.currencyUSD(availableValue.rh),
        description: 'Sum of hedges available value - intial value minus final allocations'
      })

      return rows
    }
  }
}
</script>
