var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" Lots "),_c('help-dialog',{attrs:{"headline-text":"About Allocating Lots"}},[_c('h3',[_vm._v(" When do lots appear here? ")]),_c('p',[_vm._v(" All lots that "),_c('strong',[_vm._v("are not COMPLETE")]),_vm._v(" appear on this page. ")]),_c('h3',[_vm._v(" Adding Allocations ")]),_c('p',[_vm._v(" Allocations added before the RECONCILE phase with the "),_c('v-btn',{attrs:{"x-small":""}},[_vm._v(" ADD PRE ")]),_vm._v(" button are PRE allocations and are not counted towards ZEROING a hedge. ")],1),_c('p',[_vm._v(" Allocations added in the RECONCILE phase with the "),_c('v-btn',{attrs:{"x-small":""}},[_vm._v(" ADD FIN ")]),_vm._v(" button are FINAL allocations and are counted towards ZEROING a hedge. ")],1),_c('h3',[_vm._v(" Transition a LOT to RECONCILE ")]),_c('p',[_vm._v(" When a lot is transitioned to the RECONCILE phase all existing allocations are changed to HISTORIC allocations. ")])])],1),_c('allocation-header',{attrs:{"rows":_vm.headerRows}}),_c('v-container',[_c('allocation-lot-filter-fields'),_c('v-data-table',{class:{'split-screen': !_vm.$vuetify.breakpoint.smAndDown, 'pt-6': true},staticStyle:{"cursor":"pointer"},attrs:{"id":"lots-allocations","mobile-breakpoint":500,"headers":_vm.tableHeaders,"items":_vm.dataTableRows,"expanded":_vm.expanded,"hide-default-footer":"","multi-sort":"","disable-pagination":"","fixed-header":"","item-class":_vm.rowClasses,"search":_vm.filter},on:{"click:row":_vm.expand,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'lot', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('br'),_c('small',[_vm._v(_vm._s(item.code))])]}},{key:"item.remaining_pt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-color"},[_vm._v(" "+_vm._s(_vm._f("appendRtoz")(parseFloat(item.remaining_pt).toFixed(3)))+" ")])]}},{key:"item.remaining_pd",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pd-color"},[_vm._v(" "+_vm._s(_vm._f("appendRtoz")(parseFloat(item.remaining_pd).toFixed(3)))+" ")])]}},{key:"item.remaining_rh",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"rh-color"},[_vm._v(" "+_vm._s(_vm._f("appendRtoz")(parseFloat(item.remaining_rh).toFixed(3)))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTextGivenValue(item.status, _vm.lotStatuses))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-2",staticStyle:{"cursor":"auto"},attrs:{"colspan":headers.length}},[_c('lot-expand',{attrs:{"lot":item}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }