<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col class="text-center py-1">
          Name
        </v-col>
        <v-col class="text-center py-1">
          Platinum
        </v-col>
        <v-col class="text-center py-1">
          Palladium
        </v-col>
        <v-col class="text-center py-1">
          Rhodium
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="text-center py-1">
          Smelter ID
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ hedge.pt_reference }}
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ hedge.pd_reference }}
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ hedge.rh_reference }}
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="text-center py-1">
          Hedged Metals
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ hedge.pt_initial_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ hedge.pd_initial_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ hedge.rh_initial_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="text-center py-1">
          Hedged Prices
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ hedge.pt_rate_per_toz | currencyUSD }}
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ hedge.pd_rate_per_toz | currencyUSD }}
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ hedge.rh_rate_per_toz | currencyUSD }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row
        class="text-center"
        v-for="(alloc, i) in allocations"
        :key="i"
      >
        <v-col class="text-left">
          <router-link
            :to="{ name: 'lot', params: { id: alloc.lot } }"
          >
            <v-btn
              small
              text
            >
              {{ alloc.lot_name }}
            </v-btn>
          </router-link>
          <v-btn
            class="ml-1"
            x-small
            text
            @click="$store.commit('hedgeBank/setExpandedLot', alloc.lot)"
          >
            expand on left
          </v-btn>
          <info-tooltip v-if="alloc.status === 'FIN'">
            <template v-slot:wrap-me>
              <v-icon x-small>
                mdi-lock
              </v-icon>
            </template>
            final allocation
          </info-tooltip>
        </v-col>
        <v-col class="text-center pt-color">
          {{ alloc.pt | toFixed(3) | addCommas | appendToz }}
        </v-col>
        <v-col class="text-center pd-color">
          {{ alloc.pd | toFixed(3) | addCommas | appendToz }}
        </v-col>
        <v-col class="text-center rh-color">
          {{ alloc.rh | toFixed(3) | addCommas | appendToz }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col class="text-center py-1">
          Remaining Avail. toz
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ hedge.pt_available_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ hedge.pd_available_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ hedge.rh_available_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center py-1">
          Value Avail. $
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ hedge.pt_available_value | currencyUSD }}
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ hedge.pd_available_value | currencyUSD }}
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ hedge.rh_available_value | currencyUSD }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col class="text-center py-1">
          Remaining Est. toz
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ hedge.pt_estimated_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ hedge.pd_estimated_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ hedge.rh_estimated_toz | toFixed(3) | addCommas | appendToz }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center py-1">
          Value Est. $
        </v-col>
        <v-col class="text-center py-1 pt-color">
          {{ hedge.pt_estimated_toz * hedge.pt_rate_per_toz | currencyUSD }}
        </v-col>
        <v-col class="text-center py-1 pd-color">
          {{ hedge.pd_estimated_toz * hedge.pd_rate_per_toz | currencyUSD }}
        </v-col>
        <v-col class="text-center py-1 rh-color">
          {{ hedge.rh_estimated_toz * hedge.rh_rate_per_toz | currencyUSD }}
        </v-col>
      </v-row>
      <v-textarea
        :value="hedge.memo"
        :loading="loading"
        class="mt-4"
        label="Notes"
        counter="512"
        maxlength="512"
        auto-grow
        @input="saveHedgeMemo"
      />
      <v-row
        align="center"
        justify="end"
      >
        <!--Hedges may not be completed until FIN allocations zero hedge-->
        <v-col class="ma-2">
          <v-btn
            color="primary"
            block
            :disabled="!hedgeZeroed"
            @click="completeHedge"
          >
            complete hedge
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-center">
        <div> Expiration Date: {{ hedge.expires_on | formatDate }} </div>
        <div> Created By: {{ hedge.created_by__username }} </div>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HedgeExpand',
  props: {
    hedge: {
      type: Object,
      required: true
    }
  },
  data: function () {
    // basically this doesn't get swapped when  you change tabs
    // you create crazy race conditions when you switch tabs because the data is still there
    return {
      saveTimeout: null,
      loading: false
    }
  },
  methods: {
    ...mapActions('hedgeBank', ['updateHedge']),
    saveHedgeMemo (memo) {
      clearTimeout(this.saveTimeout)
      const update = { hedge: this.hedge, payload: { memo } }
      this.saveTimeout = setTimeout(() => {
        this.loading = true
        this.updateHedge(update).finally(() => { this.loading = false })
      }, 1000)
    },
    completeHedge () {
      this.updateHedge({ hedge: this.hedge, payload: { is_complete: true } })
    }
  },
  computed: {
    ...mapGetters('hedgeBank', ['getHedgeAllocations']),
    hedgeZeroed () {
      const agg = (Number(this.hedge.pt_available_toz) +
      Number(this.hedge.pd_available_toz) +
      Number(this.hedge.rh_available_toz))
      return agg === 0
    },
    allocations () {
      return this.getHedgeAllocations(this.hedge)
    }
  }
}
</script>
