<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>Description</th>
        <th>Platinum</th>
        <th>Palladium</th>
        <th>Rhodium</th>
      </tr>
    </thead>
    <tbody class="text-right">
      <tr
        v-for="(row, index) in rows"
        :key="index"
      >
        <td
          class="subtitle-1 text-left"
        >
          {{ row.name }}
          <info-tooltip>
            {{ row.description }}
          </info-tooltip>
        </td>
        <td class="pt-color">
          {{ row.pt }}
        </td>
        <td class="pd-color">
          {{ row.pd }}
        </td>
        <td class="rh-color">
          {{ row.rh }}
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'AllocationHeader',
  props: {
    rows: {
      type: Array,
      required: true
    }
  }
}
</script>
