<template>
  <!-- Terms -->
  <v-row>
    <v-col>
      <v-card>
        <v-card-subtitle>Terms</v-card-subtitle>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              rules="max_value:100|decimal:3"
              v-slot="{ errors }"
            >
              <v-text-field
                label="PT Rate"
                type="number"
                v-model="ptReturnRate"
                class="px-4"
                append-icon="mdi-percent"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              rules="max_value:100|decimal:3"
              v-slot="{ errors }"
            >
              <v-text-field
                label="PD Rate"
                type="number"
                v-model="pdReturnRate"
                class="px-4"
                append-icon="mdi-percent"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <ValidationProvider
              rules="max_value:100|decimal:3"
              v-slot="{ errors }"
            >
              <v-text-field
                label="RH Rate"
                type="number"
                v-model="rhReturnRate"
                class="px-4"
                append-icon="mdi-percent"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="max_value:9999|decimal:3"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Treatment Charge per Pound"
                type="number"
                v-model="formData.treatmentRate"
                class="px-4"
                append-icon="mdi-currency-usd"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="max_value:100|decimal:3"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="financePercent"
                label="Finance Charge"
                type="number"
                class="px-4"
                append-icon="mdi-percent"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="max_value:100|decimal:2"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Avg Carbon Content"
                type="number"
                v-model="avgCarbonContent"
                class="px-4"
                append-icon="mdi-percent"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="max_value:100|decimal:2"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Carbon Limit per Lot"
                type="number"
                v-model="carbonLimitPerLot"
                class="px-4"
                append-icon="mdi-percent"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="decimal:0"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Value Days"
                type="number"
                v-model="formData.valueDays"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="decimal:0"
              v-slot="{ errors }"
            >
              <v-select
                label="Time Period"
                item-text="name"
                item-value="value"
                v-model="formData.timePeriod"
                class="px-4"
                :items="timePeriodOptions"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="required|min_value:0"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Expected Result Return (Days)"
                type="number"
                v-model="formData.expectedResultReturn"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="min_value:0"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Max Carbon Lbs per Period"
                type="number"
                v-model="formData.maxCarbonLbsPerPeriod"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              rules="min_value:0"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Min Lbs per Period"
                type="number"
                v-model="formData.minLbsPerPeriod"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              rules="max:512"
              v-slot="{ errors }"
            >
              <v-textarea
                label="Additional Terms"
                v-model="formData.additionalTerms"
                class="px-4"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { BigNumber } from 'bignumber.js'

export default {
  name: 'SmelterTermsEditCard',
  props: {
    smelterTerms: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    financePercent: 0,
    formData: null,
    timePeriodOptions: [
      {
        name: 'Month',
        value: 'MO'
      },
      {
        name: 'Quarter',
        value: 'QU'
      },
      {
        name: 'Year',
        value: 'YR'
      }
    ]
  }),
  watch: {
    financePercent (value) {
      this.formData.financeCharge = (value / 100).toFixed(5)
    },
    /**
     * On component entry, create local copy of the terms
     * User input results in local updated object being emitted back to parent
     */
    smelterTerms: {
      handler () {
        this.formData = this.smelterTerms
      },
      immediate: true
    },
    formData: {
      handler () {
        this.$emit('termsUpdated', this.formData)
      },
      deep: true
    }
  },
  computed: {
    // Computed getters and setters for translating api 100% = 1 values
    // to an easier-to-read 1% = 1 for input fields
    ptReturnRate: {
      get () {
        if (!this.formData) return 0
        return BigNumber(this.formData.ptReturnRate).times(100)
      },
      set (val) {
        this.formData.ptReturnRate = BigNumber(val).dividedBy(100).toFixed(4)
      }
    },
    pdReturnRate: {
      get () {
        if (!this.formData) return 0
        return BigNumber(this.formData.pdReturnRate).times(100)
      },
      set (val) {
        this.formData.pdReturnRate = BigNumber(val).dividedBy(100).toFixed(4)
      }
    },
    rhReturnRate: {
      get () {
        if (!this.formData) return 0
        return BigNumber(this.formData.rhReturnRate).times(100)
      },
      set (val) {
        this.formData.rhReturnRate = BigNumber(val).dividedBy(100).toFixed(4)
      }
    },
    carbonLimitPerLot: {
      get () {
        if (!this.formData) return 0
        return BigNumber(this.formData.carbonLimitPerLot).times(100)
      },
      set (val) {
        this.formData.carbonLimitPerLot = BigNumber(val).dividedBy(100).toFixed(2)
      }
    },
    avgCarbonContent: {
      get () {
        if (!this.formData) return 0
        return BigNumber(this.formData.avgCarbonContent).times(100)
      },
      set (val) {
        this.formData.avgCarbonContent = BigNumber(val).dividedBy(100).toFixed(2)
      }
    }
  },
  mounted () {
    // setup the finance charge
    if (this.formData.financeCharge) {
      this.financePercent = this.formData.financeCharge * 100
    }
  }
}
</script>
