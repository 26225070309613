<template>
  <v-card>
    <v-card-title>
      Contacts
    </v-card-title>
    <v-list v-if="contacts.length">
      <v-card
        flat
        v-for="contact in contacts"
        :key="contact.id"
      >
        <v-list-item>
          <v-list-item-content>
            <v-row>
              <v-col>
                <v-list-item-subtitle>
                  {{ contact.title }}
                </v-list-item-subtitle>
                <v-list-item-title
                  class="title"
                >
                  {{ contact.first_name }} {{ contact.last_name }}
                </v-list-item-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list-item-subtitle>
                  Phone Number
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ contact.phone_number | filterNA }}
                </v-list-item-title>
              </v-col>
              <v-col>
                <v-list-item-subtitle>
                  Email Address
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ contact.email | filterNA }}
                </v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-title>
          No Contacts
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'ContactsListCard',
  props: {
    contacts: {
      type: Array,
      required: false,
      default: Array
    }
  }
}
</script>
