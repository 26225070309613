<template>
  <v-text-field
    :value="value"
    label="Smelter Hedge ID"
    hint="Filter Hedges by Smelter Hedge ID"
    dense
    clearable
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'SmelterHedgeIdField',
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>
