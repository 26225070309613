<!-- Loads in own hedge manager report -->
<template>
  <v-container fluid>
    <base-title>
      Hedge Piece Manager
      <template
        v-if="username"
        v-slot:subtitle
      >
        {{ username }}
      </template>
    </base-title>
    <v-toolbar
      dense
      flat
    >
      <help-dialog
        headline-text="About"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            small
            @click="on"
          >
            About
          </v-btn>
        </template>
        <h3>
          Hedges
        </h3>
        <ul>
          <li>
            Piece count can be assigned on the hedge itself. <strong>[Hedge Manager]</strong>
          </li>
          <li>
            Only hedges for the current user are shown on this screen.
          </li>
          <li>
            Hedges will be removed when the they are marked "complete" internally.
          </li>
          <li>
            Brokered Hedges are excluded.
          </li>
        </ul>
        <h3>
          Check Ins
        </h3>
        <ul>
          <li>
            Only Check Ins for the current Account Manager will be searchable.
          </li>
          <li>
            If a check ins already assigned to a hedge it will not be searchable.
          </li>
          <li>
            Foils and Aftermarkets are not included in the row total.
          </li>
          <li>
            The <v-btn
              color="primary"
              x-small
            >
              <v-icon small>
                mdi-plus
              </v-icon>
            </v-btn> on the right of the row to allocate check-ins to buys.
          </li>
          <li>
            The <v-btn
              outlined
              x-small
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn> on the right of the row to remove an allocation.
          </li>
          <li>
            Brokered check-ins are excluded
          </li>
        </ul>
      </help-dialog>
      <v-spacer />
      <v-switch
        class="mt-4"
        v-model="detailed"
        label="Show Detailed Columns"
      />
    </v-toolbar>
    <div v-if="data && data.hedges">
      <v-card
        class="mb-8"
      >
        <v-simple-table dense>
          <thead>
            <tr>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                Created
              </th>
              <th class="text-no-wrap">
                Completed
              </th>
              <th class="text-no-wrap">
                CheckIn
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                Status
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                Purchase
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                Intake
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                Location
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                ETA
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                Created For
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                Yard
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                Lot Type
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                oem
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                aftermarket
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                diesel
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                bead
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                dpf
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                import foil
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                domestic foil
              </th>
              <th
                v-show="detailed"
                class="text-no-wrap"
              >
                generic
              </th>
              <th class="text-no-wrap text-right">
                <info-tooltip>
                  Credit / Debit: Excludes Foils
                </info-tooltip>&nbsp;C/D
              </th>
              <th class="text-no-wrap text-right">
                <info-tooltip>
                  Running Total
                </info-tooltip>&nbsp;RT
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(hedge, index) in data.hedges"
            :key="index"
          >
            <tr>
              <th
                v-show="detailed"
              >
                {{ hedge.created_at | formatDate }}
              </th>
              <th :colspan="2 + detailedCount">
                <router-link
                  v-if="$store.getters.can('update-hedge')"
                  :to="{ name: 'hedge', params: { id: hedge.id } }"
                >
                  Hedge #{{ hedge.id }}
                </router-link>
                <span v-else>
                  Hedge #{{ hedge.id }}
                </span>
              </th>
              <th class="text-right">
                {{ hedge.pieces | addCommas }}
              </th>
              <th class="text-right">
                <v-btn
                  x-small
                  color="primary"
                  @click="openAssignmentDialog(hedge)"
                >
                  <v-icon small>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </th>
            </tr>
            <tr
              v-for="(checkIn, index2) in hedge.check_ins"
              :key="'ci-' + index2"
            >
              <td
                v-show="detailed"
              >
                {{ checkIn.created_at | formatDate }}
              </td>
              <td><span v-show="detailed">{{ checkIn.completed_at | formatDate }}</span></td>
              <td>
                <router-link-id
                  :id="checkIn.id"
                  name="check-in"
                />
              </td>
              <td
                v-show="detailed"
              >
                {{ checkIn.status }}
              </td>
              <td
                v-show="detailed"
              >
                <router-link-id
                  :id="checkIn.purchase_id"
                  name="purchase"
                />
              </td>
              <td v-show="detailed">
                {{ checkIn.intake_id }}
              </td>
              <td v-show="detailed">
                {{ checkIn.current_location }}
              </td>
              <td v-show="detailed">
                {{ checkIn.eta }}
              </td>
              <td
                v-show="detailed"
              >
                {{ checkIn.account_manager }}
              </td>
              <td
                v-show="detailed"
              >
                {{ checkIn.yard }}
              </td>
              <td
                v-show="detailed"
              >
                {{ checkIn.lot_type }}
              </td>
              <td
                v-show="detailed"
                class="text-right"
              >
                {{ -1 * checkIn.oem | addCommas }}
              </td>
              <td
                v-show="detailed"
                class="text-right"
              >
                {{ -1 * checkIn.aftermarket | addCommas }}
              </td>
              <td
                v-show="detailed"
                class="text-right"
              >
                {{ -1 * checkIn.diesel | addCommas }}
              </td>
              <td
                v-show="detailed"
                class="text-right"
              >
                {{ -1 * checkIn.bead | addCommas }}
              </td>
              <td
                v-show="detailed"
                class="text-right"
              >
                {{ -1 * checkIn.dpf | addCommas }}
              </td>
              <td
                v-show="detailed"
                class="text-right"
              >
                {{ -1 * checkIn.import_foil | addCommas }}
              </td>
              <td
                v-show="detailed"
                class="text-right"
              >
                {{ -1 * checkIn.domestic_foil | addCommas }}
              </td>
              <td
                v-show="detailed"
                class="text-right"
              >
                {{ -1 * checkIn.generic | addCommas | filterNA }}
              </td>
              <td class="text-right">
                {{ -1 * checkIn.total | addCommas | negativeWrap }}
              </td>
              <th class="text-right">
                <v-btn
                  outlined
                  x-small
                  @click="unassignCheckIn(checkIn.id)"
                >
                  <v-icon small>
                    mdi-close
                  </v-icon>
                </v-btn>
              </th>
            </tr>
            <!--Display if check ins assigned or if last entry-->
            <tr v-if="hedge.check_ins.length || index === data.hedges.length - 1">
              <th :colspan="2 + detailedCount">
                <span v-if="index === data.hedges.length - 1">
                  Remaining Units Available
                </span>
              </th>
              <th v-if="detailed" />
              <th class="text-right">
                <span v-if="detailed">
                  <info-tooltip>
                    Hedge Total
                  </info-tooltip>
                  {{ hedge.remaining_pieces | addCommas | negativeWrap }}
                </span>
              </th>
              <th class="text-right">
                {{ hedge.running_total | addCommas | negativeWrap }}
              </th>
            </tr>
            <tr v-if="index !== data.hedges.length - 1">
              <td
                class="table-divider"
                :colspan="6 + detailedCount"
              />
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </div>
    <text-fab
      v-if="this.$store.getters.can('view-hedge-manager-pieces')"
      bottom
      right
      fixed
      icon-text="mdi-account-switch-outline"
      @click="userSwitchDialog = true"
    >
      Switch Users
    </text-fab>
    <edit-dialog
      headline-text="Switch Users"
      v-model="userSwitchDialog"
      accept-text="Go"
      :inputs-valid="!!userSwitchSelect"
      @accept="$router.push({ name: 'hedge-manager-report-user', params: { id: userSwitchSelect.id } })"
    >
      <search-autocomplete
        v-model="userSwitchSelect"
        :params="{ fields: 'username,id', ordering: 'username', is_active: true }"
        label="Created By Search"
        return-object
        api-path="/api/users/"
        :format-entry="x => ({ friendlyName: x.username, username: x.username, id: x.id })"
      />
    </edit-dialog>
    <edit-dialog
      v-if="assignmentDialog"
      v-model="assignmentDialog"
      headline-text="Assign Check In"
      accept-text="Add"
      @accept="assignCheckInToHedgeByAccept"
    >
      <v-card-text>
        Click on a line item add button to add it immediately or search for a check in by id and click the add button to
        attached
        a check in to <strong>hedge #{{ assignmentHedge.id }}</strong>.
        <v-simple-table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Yard</th>
              <th class="text-right">
                Add
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ci, cii) in recentBuys"
              :key="'ci-' + cii"
            >
              <td>
                {{ ci.id }}
              </td>
              <td>
                {{ ci.yard_name }}
              </td>
              <td class="text-right">
                <v-btn
                  color="primary"
                  x-small
                  @click="assignCheckInToHedgeByLine(ci)"
                >
                  <v-icon small>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <search-autocomplete
        v-model="assignmentCheckInObj"
        label="CheckIn ID Search"
        hint="type to start searching"
        placeholder="Start typing to Search"
        :api-path="`/api/check_ins/?hedge_piece_allocation__isnull=true&fields=url,id,yard_name&purchase__designation=HS,SP&ordering=-id&created_for__id=${userId}`"
        :format-entry="x => Object.assign({friendlyName: `${x.id} ${x.yard_name}`}, x)"
      />
    </edit-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import RouterLinkId from '../../base/components/RouterLinkId.vue'
import SearchAutocomplete from '../../base/components/SearchAutocomplete.vue'
import { fetchUserById } from '../../requests/user'

export default {
  name: 'HedgeManagerReport',
  components: {
    SearchAutocomplete,
    RouterLinkId
  },
  data: () => ({
    userId: null,
    username: null,
    data: null,
    detailed: false,
    assignmentDialog: false,
    assignmentHedge: null,
    assignmentCheckInObj: null,
    userSwitchDialog: false,
    userSwitchSelect: null,
    recentBuys: []
  }),
  computed: {
    detailedCount () {
      return this.detailed ? 16 : 0
    }
  },
  methods: {
    fetchRecentBuys () {
      this.recentBuys = []
      const params = {
        'hedge_piece_allocation__isnull': true,
        'page_size': 5,
        'fields': 'url,id,yard_name',
        'ordering': '-id',
        'created_for__id': this.userId,
        'purchase__designation': 'HS,SP,TO,DR'
      }
      return axios.get('/api/check_ins/', { params }).then(res => {
        this.recentBuys = res.data.results
      })
    },
    unassignCheckIn (checkInId) {
      if (!confirm('Are you sure you would like to unassign Check In #' + checkInId)) return
      axios.patch(`/api/check_ins/${checkInId}/`, {
        'hedge_piece_allocation_id': null
      }).then(() => {
        this.$store.commit('setSnackbarInfo', 'Yard Removed #' + checkInId)
        this.fetchHedgePieces()
      }).catch(() => {
        this.$store.commit('setSnackbarError', 'Failed to remove yard')
      })
    },
    assignCheckInToHedgeByLine (checkInObj) {
      this.assignCheckInToHedge(checkInObj)
      this.assignmentDialog = false
    },
    assignCheckInToHedgeByAccept () {
      if (!this.assignmentCheckInObj) {
        this.$store.commit('setSnackbarWarning', 'Unable to apply assignment - No Check In Obj')
        return
      }
      this.assignCheckInToHedge(this.assignmentCheckInObj)
    },
    assignCheckInToHedge (checkInObj) {
      if (!this.assignmentHedge) {
        this.$store.commit('setSnackbarWarning', 'Unable to apply assignment - No Hedge')
        return
      }
      axios.patch(`/api/check_ins/${checkInObj.id}/`, {
        'hedge_piece_allocation_id': this.assignmentHedge.id
      }).then(() => {
        this.$store.commit('setSnackbarInfo', 'Yard Added #' + checkInObj.id)
        this.fetchHedgePieces()
        checkInObj = null
      }).catch(() => {
        this.$store.commit('setSnackbarError', 'Failed to save yard')
      })
    },
    openAssignmentDialog (hedge) {
      this.assignmentHedge = hedge
      this.fetchRecentBuys().then(() => {
        this.assignmentDialog = true
      })
    },
    /**
     * Expect these users hedges to be returned if userId is none
     * @param {*} userId
     */
    fetchHedgePieces () {
      const params = { user: this.userId }
      axios.get('/api/hedges/pieces/', { params }).then(res => {
        this.data = res.data
      })
    },
    fetchUser () {
      fetchUserById(this.userId).then(response => {
        this.username = response.data?.username
      })
    }
  },
  mounted () {
    let userId = this.$route.params.id
    if (!userId) userId = this.$store.getters['currentUser'].id
    this.userId = userId
    this.fetchUser()
    this.fetchHedgePieces()
  }
}
</script>

<style>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td.table-divider {
  height: 2px;
  background-color: gray;
}
</style>
