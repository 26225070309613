<template>
  <v-card>
    <v-card-title class="headline justify-center">
      Lots
      <help-dialog
        headline-text="About Allocating Lots"
      >
        <h3>
          When do lots appear here?
        </h3>
        <p>
          All lots that <strong>are not COMPLETE</strong> appear on this page.
        </p>
        <h3>
          Adding Allocations
        </h3>
        <p>
          Allocations added before the RECONCILE phase with the
          <v-btn x-small>
            ADD PRE
          </v-btn>
          button are PRE allocations and are not counted towards ZEROING a hedge.
        </p>
        <p>
          Allocations added in the RECONCILE phase with the
          <v-btn x-small>
            ADD FIN
          </v-btn>
          button are FINAL allocations and are counted towards ZEROING a hedge.
        </p>
        <h3>
          Transition a LOT to RECONCILE
        </h3>
        <p>
          When a lot is transitioned to the RECONCILE phase all existing allocations are changed to HISTORIC
          allocations.
        </p>
      </help-dialog>
    </v-card-title>
    <allocation-header :rows="headerRows" />
    <v-container>
      <allocation-lot-filter-fields />
      <v-data-table
        id="lots-allocations"
        @click:row="expand"
        :mobile-breakpoint="500"
        :headers="tableHeaders"
        :items="dataTableRows"
        :expanded.sync="expanded"
        hide-default-footer
        multi-sort
        disable-pagination
        fixed-header
        :class="{'split-screen': !$vuetify.breakpoint.smAndDown, 'pt-6': true}"
        style="cursor: pointer"
        :item-class="rowClasses"
        :search="filter"
      >
        <template v-slot:item.name="{ item }">
          <router-link :to="{ name: 'lot', params: { id: item.id } }">
            {{ item.name }}
          </router-link>
          <br>
          <small>{{ item.code }}</small>
        </template>
        <template v-slot:item.remaining_pt="{ item }">
          <div class="pt-color">
            {{ parseFloat(item.remaining_pt).toFixed(3) | appendRtoz }}
          </div>
          <!-- {{ containedOz(item).pt | toFixed(3) | addCommas | appendCtoz }} -->
        </template>
        <template v-slot:item.remaining_pd="{ item }">
          <div class="pd-color">
            {{ parseFloat(item.remaining_pd).toFixed(3) | appendRtoz }}
          </div>
          <!-- {{ containedOz(item).pd | toFixed(3) | addCommas | appendCtoz }} -->
        </template>
        <template v-slot:item.remaining_rh="{ item }">
          <div class="rh-color">
            {{ parseFloat(item.remaining_rh).toFixed(3) | appendRtoz }}
          </div>
          <!-- {{ containedOz(item).rh | toFixed(3) | addCommas | appendCtoz }} -->
        </template>
        <template v-slot:item.status="{ item }">
          {{ getTextGivenValue(item.status, lotStatuses) }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-2"
            style="cursor: auto"
          >
            <lot-expand :lot="item" />
          </td>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import AllocationHeader from './AllocationHeader'
import { mapGetters } from 'vuex'
import LotExpand from './LotExpand'
import _ from 'underscore'
import AllocationLotFilterFields from '@/views/Hedges/Allocations/components/Inputs/AllocationLotFilterFields.vue'

export default {
  name: 'AllocationsLots',
  components: { AllocationLotFilterFields, LotExpand, AllocationHeader },
  data: () => ({
    filter: '',
    expanded: [],
    tableHeaders: [
      { text: 'Name', value: 'name' },
      { text: 'Platinum', value: 'remaining_pt' },
      { text: 'Palladium', value: 'remaining_pd' },
      { text: 'Rhodium', value: 'remaining_rh' },
      { text: 'Status', value: 'status' }
    ]
  }),
  watch: {
    getExpandedLot (id) {
      let found = this.dataTableRows.find(x => x.id === id)
      if (found) {
        this.expanded = [found]
      } else if (id) {
        this.$store.commit('setSnackbarError', 'lot is not present in hedge bank')
      }
    }
  },
  methods: {
    rowClasses (row) {
      const total = Number(row.remaining_pt) + Number(row.remaining_pd) + Number(row.remaining_rh)
      if (total !== 0) return ''
      if (this.$vuetify.theme.dark) {
        return 'grey darken-3'
      } else {
        return 'grey lighten-4'
      }
    },
    expand (row) {
      this.$store.commit('hedgeBank/setExpandedLot', '')
      this.expanded = (this.expanded.find(e => e.id === row.id)) ? [] : [row]
    }
  },
  computed: {
    ...mapGetters('lotStore', [
      'lotStatuses'
    ]),
    ...mapGetters('hedgeBank', [
      'getLots',
      'getAllocations',
      'getLotAllocations',
      'getExpandedLot'
    ]),
    dataTableRows () {
      const lotAllocations = this.getLotAllocations
      return this.getLots.map(l => {
        l.remaining_pt = Number(_.isNull(l.returnable_pt) ? l.estimated_returnable_pt : l.returnable_pt)
        l.remaining_pd = Number(_.isNull(l.returnable_pd) ? l.estimated_returnable_pd : l.returnable_pd)
        l.remaining_rh = Number(_.isNull(l.returnable_rh) ? l.estimated_returnable_rh : l.returnable_rh)
        lotAllocations(l).forEach(a => {
          l.remaining_pt = (l.remaining_pt - Number(a.pt)).toFixed(3)
          l.remaining_pd = (l.remaining_pd - Number(a.pd)).toFixed(3)
          l.remaining_rh = (l.remaining_rh - Number(a.rh)).toFixed(3)
        })
        return l
      })
    },
    allocationTotals () {
      const lotIds = this.getLots.map(l => l.id)
      return this.getAllocations
        .filter(a => lotIds.includes(a.lot))
        .reduce((c, a) => {
          c.pt += Number(a.pt)
          c.pd += Number(a.pd)
          c.rh += Number(a.rh)
          return c
        }, { pt: 0, pd: 0, rh: 0 })
    },
    headerRows () {
      const allocated = this.allocationTotals
      const estimatedContained = this.getLots.reduce((c, l) => {
        l.pd += Number(l.contained_pd || l.estimated_pd)
        l.pt += Number(l.contained_pt || l.estimated_pt)
        l.rh += Number(l.contained_rh || l.estimated_rh)
        return c
      }, { pt: 0, pd: 0, rh: 0 })
      const estimatedReturnable = this.getLots.reduce((c, l) => {
        l.pd += Number(l.returnable_pd || l.estimated_returnable_pd)
        l.pt += Number(l.returnable_pt || l.estimated_returnable_pt)
        l.rh += Number(l.returnable_rh || l.estimated_returnable_rh)
        return c
      }, { pt: 0, pd: 0, rh: 0 })

      return [
        // how many OZ do we have?
        { name: 'Estimated Contained', ...estimatedContained },
        // how many oz of that are returnable
        { name: 'Estimated Returnable', ...estimatedReturnable },
        // total unallocated oz
        {
          name: 'Unallocated total',
          pt: (estimatedReturnable.pt - allocated.pt).toFixed(3),
          pd: (estimatedReturnable.pd - allocated.pd).toFixed(3),
          rh: (estimatedReturnable.rh - allocated.rh).toFixed(3)
        }]
    }
  }
}
</script>

<style>
#lots-allocations td {
  padding: 0;
}
</style>
