<template>
  <v-dialog
    :value="value"
    width="500"
    @input="$emit('input', $event)"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Hedge Allocations View</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <download-excel
            :data="hedges"
            :fields="excelHeaders"
            :name="excelName"
            worksheet="Sheet1"
          >
            <v-btn
              class="mt-4"
              dark
              text
            >
              Save
              <v-icon class="pl-2">
                mdi-microsoft-excel
              </v-icon>
            </v-btn>
          </download-excel>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title>
        {{ getSmelter && getSmelter.name }}
      </v-card-title>
      <v-card-subtitle>
        {{ (new Date()).toLocaleDateString() }}
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          disable-pagination
          hide-default-footer
          :headers="headers"
          :items="hedges"
          dense
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

const CREATED_DATE = 'created'
const ARRIVAL_DATE = 'arrival'
const VENDOR = 'vendor'
const METAL_TYPE = 'metal'
const UNIT_PRICE = 'price'
const INITIAL = 'initial'
const APPLIED = 'applied'
const BALANCE = 'balance'
export default {
  name: 'HedgeAllocationExportView',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    baseHeaders: [
      { text: 'Hedge', value: 'id' },
      { text: 'Date', value: CREATED_DATE },
      { text: 'Est Arrival', value: ARRIVAL_DATE },
      { text: 'Vendor Name', value: VENDOR },
      { text: 'Metal Type', value: METAL_TYPE },
      { text: 'Unit Price ($)', value: UNIT_PRICE },
      { text: 'Hedge', value: INITIAL },
      { text: 'Applied', value: APPLIED },
      { text: 'Balance TOZ', value: BALANCE }
    ],
    baseExcelHeaders: {
      'Date': CREATED_DATE,
      'Est Arrival': ARRIVAL_DATE,
      'Vendor Name': VENDOR,
      'Metal Type': METAL_TYPE,
      'Unit Price ($)': UNIT_PRICE,
      'Hedge': INITIAL,
      'Applied': APPLIED,
      'Balance TOZ': BALANCE
    }
  }),
  computed: {
    excelName () {
      const lotNames = this.reviewLots.map(l => l.name).join(' ')
      return 'Hedge Allocations ' + lotNames + '.xls'
    },
    excelHeaders () {
      return this.reviewLots.reduce((headers, l) => {
        headers[l.shipment_customer_name] = l.shipment_customer_name
        return headers
      }, this.baseExcelHeaders)
    },
    headers () {
      const lots = this.reviewLots.map(l => ({ text: l.shipment_customer_name, value: l.shipment_customer_name }))
      return [...this.baseHeaders, ...lots]
    },
    hedges () {
      let matrix = []
      this.getHedges.forEach(hedge => {
        const applied = this.calculateApplied(hedge)
        const balance = this.calculateBalances(hedge)
        ;['pt', 'pd', 'rh'].forEach(metal => {
          if (Number(hedge[metal + '_initial_toz']) === 0) return
          let line = {}
          line['id'] = hedge.id + '-' + metal
          line[CREATED_DATE] = hedge.created_at.substr(0, 10)
          line[ARRIVAL_DATE] = hedge.expires_on.substr(0, 10)
          line[VENDOR] = 'Phoenix Group Metals, LLC'
          line[METAL_TYPE] = metal.toUpperCase()
          line[UNIT_PRICE] = '$' + hedge[metal + '_rate_per_toz']
          line[INITIAL] = hedge[metal + '_initial_toz']
          line[APPLIED] = applied[metal]
          line[BALANCE] = balance[metal]
          this.reviewLots.forEach(lot => {
            const a = this.getFinalAllocation(hedge, lot)
            line[lot.name] = (a) ? Number(a[metal]) : 0
          })
          matrix.push(line)
        })
      })
      return matrix
    },
    ...mapGetters('lotStore', [
      'lotTypes'
    ]),
    ...mapGetters('hedgeBank', [
      'getSmelter',
      'getLotType',
      'getHedges',
      'getLots',
      'getHedgeAllocations'
    ]),
    // returns lots that are in status RC-oncile
    reviewLots () {
      return this.getLots.filter(l => l.status === 'RC')
    }
  },
  methods: {
    getFinalAllocation (hedge, lot) {
      return this.getHedgeAllocations(hedge).find(a => a.status === 'FIN' && a.lot === lot.id)
    },
    // gets total "FINAL" allocations EXCEPT for review lots
    calculateApplied (hedge) {
      const excludeLotKeys = this.reviewLots.map(l => l.id)
      let applied = this.getHedgeAllocations(hedge).reduce((carry, allocation) => {
        // exclude if not a finished allocation
        if (allocation.status !== 'FIN') return carry
        // exclude if is in review
        if (excludeLotKeys.includes(allocation.lot)) return carry
        carry.pt += Number(allocation.pt)
        carry.pd += Number(allocation.pd)
        carry.rh += Number(allocation.rh)
        return carry
      }, { pt: 0, pd: 0, rh: 0 })
      return {
        pt: applied.pt.toFixed(3),
        pd: applied.pd.toFixed(3),
        rh: applied.rh.toFixed(3)
      }
    },
    // returns initial balance minus applied
    calculateBalances (hedge) {
      const applied = this.calculateApplied(hedge)
      return {
        pt: (Number(hedge.pt_initial_toz) - Number(applied.pt)).toFixed(3),
        pd: (Number(hedge.pd_initial_toz) - Number(applied.pd)).toFixed(3),
        rh: (Number(hedge.rh_initial_toz) - Number(applied.rh)).toFixed(3)
      }
    }
  }
}
</script>
