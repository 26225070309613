<template>
  <div>
    <slot
      name="activator"
      :on="() => createSmelterDialog = !createSmelterDialog"
    >
      <v-btn
        :disabled="!$store.getters.can('create-smelters')"
        small
        color="primary"
        @click="createSmelterDialog = !createSmelterDialog"
      >
        Create Smelter
      </v-btn>
    </slot>
    <ValidationObserver
      v-slot="{ invalid }"
      ref="validationObserver"
    >
      <v-form
        v-model="formValid"
        @submit.prevent="createSmelter"
      >
        <edit-dialog
          headline-text="Create Smelter"
          v-model="createSmelterDialog"
          :inputs-valid="(!invalid && formValid)"
          accept-text="Create"
          @accept="createSmelter"
        >
          <ValidationProvider
            rules="required|max:30"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="newName"
              label="Name"
              :error-messages="errors"
            />
          </ValidationProvider>
          <!-- Need to verify it's unique -->
          <ValidationProvider
            rules="required|max:10"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="newCode"
              label="Code"
              :error-messages="errors"
              :rules="[x => !usedCodes.includes(x.toUpperCase()) || 'Must be Unique']"
            />
          </ValidationProvider>
        </edit-dialog>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CreateSmelterDialog',
  data: () => ({
    formValid: false,
    newName: '',
    newCode: '',
    createSmelterDialog: false,
    usedCodes: []
  }),
  watch: {
    /**
     * Fetches the "Used Codes" for validation
     * @param opened
     */
    createSmelterDialog (opened) {
      if (opened) {
        // fetches the already used coes
        axios.get('/api/smelters/?fields=code&page_size=0').then(res => {
          this.usedCodes = res.data.map(x => x.code)
        })
      }
    }
  },
  methods: {
    /**
     * Creates a smelter, verifies permission and no conflicts
     * @returns {Promise<void>}
     */
    async createSmelter () {
      if (!this.$store.getters.can('create-smelters')) {
        this.$store.commit('setSnackbarError', 'Unauthorized to create smelter')
        return
      }
      const name = this.newName
      const code = this.newCode.toUpperCase()
      const payload = { name, code, contacts: [] }
      // request to ensure no codes already exist matching this param
      const codeRes = await axios.get('/api/smelters/', { params: { code }, fields: '' })
      if (!codeRes.data) {
        this.$store.commit('setSnackbarError', 'Failed to preform code check. Please try again.')
        return
      }
      // ensure there are no conflicts --- should have already been verified in form
      if (codeRes.data.count > 0) {
        this.$store.commit('setSnackbarError', 'Found a conflicting Smelter CODE. Please change the code and try again.')
        return
      }
      this.newName = ''
      this.newCode = ''
      // Create and redirect on success
      const createRes = await axios.post('/api/smelters/', payload)
      if (createRes.status !== 201) {
        this.$store.commit('setSnackbarError', `Failed to create smelter`)
        return
      }
      this.$router.push({ name: 'smelter', params: { id: createRes.data.id } })
    }
  }
}
</script>
