<template>
  <unauthorized
    :authorized="$store.getters.can('view-smelters')"
    message="Unauthorized to view Smelters"
  >
    <base-title
      title="Smelter List"
    />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <router-link-id
          :id="item.id"
          name="smelter"
        >
          {{ item.name }}
        </router-link-id>
      </template>
      <template v-slot:item.is_active="{ item }">
        {{ item.is_active ? 'Yes' : 'No' }}
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />

    <create-smelter-dialog
      v-if="$store.getters.can('create-smelters')"
    >
      <template v-slot:activator="{ on }">
        <text-fab
          bottom
          right
          fixed
          icon-text="mdi-plus"
          color="primary"
          @click="on"
        >
          Create Smelter
        </text-fab>
      </template>
    </create-smelter-dialog>
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
import { mapGetters } from 'vuex'
import CreateSmelterDialog from '@/views/Smelter/components/CreateSmelterDialog'

export default {
  mixins: [chipsMixin, paginationMixin],
  name: 'SmelterList',
  components: {
    CreateSmelterDialog,
    ResultPagination,
    SearchFilters
  },
  data: function () {
    return {
      pageName: 'smelter-list',
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Active', value: 'is_active' },
        { text: 'Code', value: 'code' },
        { text: 'Email', value: 'email' },
        { text: 'Phone Number', value: 'phone_number' },
        { text: 'Return Time (days)', value: 'expected_result_return' }
      ],

      ...this.chipPropagation([
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  computed: {
    ...mapGetters({
      lotDesignations: 'lotStore/lotTypes',
      lotStatuses: 'lotStore/lotStatuses',
      lotMaterialType: 'lotStore/lotMaterialType'
    })
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        // 'url',
        'id',
        'name',
        'phone_number',
        'email',
        'code',
        'expected_result_return',
        'is_active'
      ].join(',')
      params.is_proposed = false
      axios.get('/api/smelters/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
