<template>
  <v-text-field
    :value="value"
    label="Lot Name"
    hint="Filter Lots by name"
    dense
    clearable
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'LotNameField',
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>
